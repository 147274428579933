import React, { useState } from 'react';
import './NotificationDropdown.scss';
import { FaBell, FaRegBell, FaTimes } from "react-icons/fa";
import { API_ENDPOINT_MARK_NOTIFICATIONS_AS_READ, API_ENDPOINT_NOTIFICATIONS } from '../../../data/Endpoints-API';
import { useAuthContext } from '../../../contexts/Auth-Context';
import APIMiddleware from '../../../data/api/Api-Middleware';
import { adjustDateTime, formatDate } from '../../../utils/format';
import { useMetricsContext } from '../../../contexts/Metrics-Context';


const NotificationDropdown = () => {
    const {
        user
      } = useAuthContext();
      const { utcOffset, metrics } =  useMetricsContext();

    const [isOpen, setIsOpen] = useState(false);
    const [notifications , setNotifications ] = useState([]);

    const toggleDropdown = async () => {
        // await getUserNotifications()
        if (!isOpen) {
          // Modal is opening
          await getUserNotifications();
      } else {
          // Modal is closing
          await markNotifictionsAsRead();
      }
        setIsOpen(!isOpen);
    } 


    const getUserNotifications = async () => {
        try {
          if (user && user.userId != undefined && user.userId > 0) {
            const response = await APIMiddleware.get(
              API_ENDPOINT_NOTIFICATIONS(user.userId),
            );
            const responseData = response.data;
                setNotifications(responseData)
                
          }
        } catch (error) {
          // Handle API request error
          console.error(`API request error: ${API_ENDPOINT_NOTIFICATIONS}`, error);
        }
      };

    const markNotifictionsAsRead = async () => {
        try {
          if (user && user.userId != undefined && user.userId > 0) {
            const response = await APIMiddleware.get(
              API_ENDPOINT_MARK_NOTIFICATIONS_AS_READ(user.userId),
            );
            const responseData = response.data;
                setNotifications(responseData)
                
          }
        } catch (error) {
          // Handle API request error
          console.error(`API request error: ${API_ENDPOINT_MARK_NOTIFICATIONS_AS_READ}`, error);
        }
      };

    return (
        <div className="notification-dropdown">
            <div onClick={toggleDropdown} className="notification-icon">
                {metrics.userUnseenNotificationsCount > 0 ? <FaBell size={20} color='#c5c5c5'/> : <FaRegBell size={20} color='#c5c5c5'/>}
                {metrics.userUnseenNotificationsCount > 0 && <span className="notification-count">{metrics.userUnseenNotificationsCount}</span>}
            </div>
            {isOpen && (
                <div className="notification-list">
                    <div className="heading">
                        <h5>Notifications</h5>
                          <button
                            onClick={() => {
                              setIsOpen(false);
                            }}
                            className="close-icon"
                          >
                            <FaTimes />
                          </button>
                    </div>
                    <div className="notification-list-item">
                    {notifications.length === 0 ? (
                        <div className="no-notifications">No notifications found</div>
                      ) : (
                        notifications.map((notification, index) => (
                          <div key={index} className="notification-item">
                            <div className="message">{notification.message}</div>
                            <div className="date">
                              {formatDate(adjustDateTime(notification.created_at, utcOffset))}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationDropdown;
