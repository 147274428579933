import React, { useState, useEffect } from "react";
import Spinner from "../../utils/spinner/Spinner";
import { toast } from "react-toastify";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import { API_ENDPOINT_OPEN_ORDER } from "../../../data/Endpoints-API.js";
import APIMiddleware from "../../../data/api/Api-Middleware.js";
import { useAccountManagerContext } from "../../../contexts/Account-Manager-Context.js";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import { useMetricsContext } from "../../../contexts/Metrics-Context.js";
import { Order } from "../../../interfaces/Order.js";
import { formatPositionToPipSize, formatPrice } from "../../../utils/format.js";
import { useOrderContext } from "../../../contexts/Order-Context.js";
import SLTP from "../market_order/SLTP.jsx";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { Ripple } from 'react-ripple-click';
import 'react-ripple-click/dist/index.css';
import UTCTimeConverter from "../order_panel/UTCTimeConverter.jsx";

//Declaring props
interface ChildProps {
  selectedOrderTab: any;
}
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '15px !important',
    width: '150px',
    borderColor: '#484848 !important',
    backgroundColor: 'transparent !important',
    boxShadow: state.isFocused ? '#484848 !important' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#484848 !important' : provided.borderColor,
      cursor: 'pointer',
    },
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px !important',
    color: 'white',
    borderColor: '#484848 !important',
    backgroundColor: state.isFocused ? 'rgb(33, 196, 109) !important;' : null,
    '&:hover': {
      backgroundColor: 'rgb(33, 196, 109) !important',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#3b3a3a !important',
    zIndex: 111,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '120px',
    overflowY: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    padding: '0 !important'
  }),
};
const orderCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '15px !important',
    width: '75px',
    borderColor: '#484848 !important',
    backgroundColor: 'transparent !important',
    boxShadow: state.isFocused ? '#484848 !important' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#484848 !important' : provided.borderColor,
      cursor: 'pointer',
    },
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px !important',
    color: 'white',
    borderColor: '#484848 !important',
    backgroundColor: state.isFocused ? 'rgb(33, 196, 109) !important;' : null,
    '&:hover': {
      backgroundColor: 'rgb(33, 196, 109) !important',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#3b3a3a !important',
    zIndex: 111,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '120px',
    overflowY: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    padding: '0 !important'
  }),
};

const PendingOrder: React.FC<ChildProps> = ({ selectedOrderTab }) => {
  //CONTEXT
  const {
    loadingSymbolContext,
    
    bidPrice,
    askPrice,
    symbolInfo,
    marketHours,
    symbolData,
    updateSymbolData,
    allSymbolOptions,
    selectedSymbolOption,
    handleSymbolOptionChange,
    leverage,
    selectedSymbolExchangeRate,
    lotSteps,
    lotSize,
    unitOptions
  } = useSymbolContext();
  const { openOrder, setActiveTab } = useAccountManagerContext();
  const { user, sendDataToServer,selectedAuthSymbol,platFromData } = useAuthContext();
  const { metrics } = useMetricsContext();
  const [buyActive, setBuyActive] = useState(true);
  const [sellActive, setSellActive] = useState(false);
  const [direction, setDirection] = useState("Buy");
  const [orderQuantity, setOrderQuantity] = useState(0.0);
  const [stopLoss, setStopLoss] = useState(0.0);
  const [slPips, setSlPips] = useState(0.0);
  const [tpPips, setTpPips] = useState(0.0);
  const [takeProfit, setTakeProfit] = useState(0.0);
  const [orderEntryPrice, setOrderEntryPrice] = useState(0.0);
  const [expiresAt, setExpiresAt] = useState("");

  const [pointerEvents, setPointerEvents] = useState("all");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [preDefineQuantities, setPreDefineQuantities] = useState("false");
  const [orderComment, setOrderComment] = useState("");
  const [assetState, setAssetState] = useState({
    type: "base_asset",
    name: symbolInfo.base_asset,
  });
  const [amount, setAmount] = useState(0.0);
  const [expiresAtStatus, setExpiresAtStatus] = useState(false);
  const [isOrderOpening, setIsOrderOpening] = useState(false);
  const [selectedLotStep, setSelectedLotStep] = useState(0.0);
  const [selectedLotStepOption, setSelectedLotStepOption] = useState({ value: 0.0, label: '0.0 Lots' });
  const [inputValue, setInputValue] = useState('');
    
  const [inputQtyValue, setInputQtyValue] = useState('');
  const [selectedQtyOption, setSelectedQtyOption] = useState({ value: 0.0, label: '0.0' });
  const formatCreateLabelLots = (inputValue) => `${inputValue} Lots`;
  const formatCreateLabelQty = (inputValue) => `${inputValue}`;



  const handleProfitChange = (data) => {
    setTakeProfit(data)
  };
  const handleLossChange = (data) => {
    setStopLoss(data)
  };
  const handleTPPipsChange = (data) => {
    setTpPips(data)
  };
  const handleSLPipsChange = (data) => {
    setSlPips(data)
  };

  useEffect(() => {
    setAssetState({type: "base_asset",
    name: symbolInfo.base_asset});
    if (symbolInfo.trade_type === 'units') {
      const unitFirst = unitOptions?.length > 0 ? unitOptions[0] : { value: 0.0, label: '0.0' };
      setOrderQuantity(parseFloat(unitFirst.value));
      setSelectedQtyOption(unitFirst);
    } else {
      const lotFirst = lotSteps?.length > 0 ? lotSteps[0] : { value: 0.0, label: '0.0' };
      setSelectedLotStep(parseFloat(lotFirst.value));
      setSelectedLotStepOption(lotFirst);
    }
  }, [lotSteps, symbolInfo, unitOptions]);

  useEffect(() => {
  }, [
    bidPrice,
    askPrice,
    orderEntryPrice,
  ]);

  useEffect(() => {
    setOrderQuantity(selectedLotStep * lotSize)
 }, [selectedLotStep]);

  useEffect(() => {
    if (selectedOrderTab === "pending-order" && bidPrice > 0 && askPrice) {
      setOrderEntryPrice(
        direction == "Buy" ? parseFloat(askPrice) : parseFloat(bidPrice)
      );
    }
  }, [selectedOrderTab, direction, selectedAuthSymbol]);

  useEffect(() => {
    setTakeProfit(0.0);
    setStopLoss(0.0);
    setSelectedLotStep(0.0);
  }, [selectedAuthSymbol]);


  const handleDateTimeChange = (event) => {
    setExpiresAt(event);
  };
  const handleDisabledButton = (data) => {
    setIsButtonDisabled(data)
  };
  useEffect(() => {
    //If amount entry price and quantity is not null or nan then it will pass to the condition
    if (!isNaN(amount) && !isNaN(orderEntryPrice) && !isNaN(orderQuantity)) {
      //If the selected type is base asset (BTC)
      //then it will set amount according to given quantity
      if (assetState.type === "base_asset") {
        setAmount(orderEntryPrice * orderQuantity);
      }
      //If the selected type is quote asset (USDT)
      //then it will set quantity according to given amount
      else if (assetState.type === "quote_asset") {
        setOrderQuantity(amount / orderEntryPrice);
      }
    }
  }, [amount, orderQuantity, orderEntryPrice]);


  const handleAssetChange = (selectedOption) => {
    const { value } = selectedOption;
  
    setAmount(0.0);
    setOrderQuantity(0.0);
    setInputQtyValue('');
    setSelectedQtyOption({ value: 0.0, label: '0.0' });
  
    // Create a new object with updated values based on selection
    const updatedAssetState = {
      type: value === symbolInfo.base_asset ? "base_asset" : "quote_asset",
      name: value,
    };
  
    // Update the state using the updated object
    setAssetState(updatedAssetState);
  };
  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '3px !important',
      width: '75px',
      borderColor: '#484848 !important',
      backgroundColor: '#00000000!important',
      marginLeft: '10px !important', // Add margin on the left
      boxShadow: state.isFocused
        ? '0 0 50px #484848 !important'
        : provided.boxShadow,
      cursor: 'pointer',
      '&:hover': {
        borderColor: 'rgb(33, 196, 109) !important',
        cursor: 'pointer',
        boxShadow: '0 0 0 transparent !important',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: '10px !important',
      borderColor: state.isFocused
        ? 'rgb(33, 196, 109) !important'
        : '#484848 !important',
      color: '#c5c5c5 !important',
    }),
    option: (provided, state, inSettings = true) => ({
      ...provided,
      padding: inSettings ? '0.3rem !important' : '0 !important',
      cursor: 'pointer',
      color: state.isSelected
        ? 'rgb(33, 196, 109) !important'
        : '#c5c5c5 !important',
      borderBottom: '1px solid #232323',
      backgroundColor: state.isSelected ? '#232323 !important' : 'transparent',
      '&:hover': {
        backgroundColor: 'rgb(33, 196, 109) !important',
        color: '#2d2d2d !important',
      },
      '&:nth-last-child(1)': {
        borderBottom: 'none !important',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#3b3a3a !important',
      margin: '0.3rem 0.8rem 0 1rem !important', // Add margin on the left
      zIndex: 111,
      left: '-10px', // Adjust position if needed
      width: '80px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '100px', // Reduced height
      minHeight: '3px',
      width: '80px', // Ensures the menu list width matches the menu container
      overflowY: 'auto',
    }),
  };
  

  const expiryDate = (e) => {
    if (e.target.checked == true) {
      e.target.parentNode.children[0].setAttribute("aria-checked", true);
      setExpiresAtStatus(e.target.checked);
    } else {
      e.target.parentNode.children[0].setAttribute("aria-checked", false);
      setExpiresAt("");
      setExpiresAtStatus(e.target.checked);
    }
  };
  const getMinimumLeverage = (userLeverage, groupLeverage, symbolLeverage) => {

    const parsedUserLeverage = parseFloat(userLeverage);
    const parsedGroupLeverage = parseFloat(groupLeverage);
    const parsedSymbolLeverage = parseFloat(symbolLeverage);
    // If groupLeverage is defined and not NaN
    if (groupLeverage !== undefined && groupLeverage !== null && groupLeverage !== 'null' && !isNaN(parsedGroupLeverage)) {
      return Math.min(parsedUserLeverage, parsedGroupLeverage);
    }
  
    // If symbolLeverage is defined and not NaN
    if (symbolLeverage !== undefined && symbolLeverage !== null && symbolLeverage !== 'null' && !isNaN(parsedSymbolLeverage)) {
      return Math.min(parsedUserLeverage, parsedSymbolLeverage);
    }
  
    // Default to userLeverage or 1 if userLeverage is NaN
    return isNaN(parsedUserLeverage) ? 1 : parsedUserLeverage;
  };

  const countLetters = (e) => {
    const counter = e.target.value.split("").length;
    const countBox = document.getElementById("count-comment-letters-po");
    if (counter <= 100 && countBox != null) {
      countBox.innerText = counter;
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  const handleBuySellOrderClick = (buy: boolean) => {
    setBuyActive(buy);
    setSellActive(!buy);
    setDirection(buy ? "Buy" : "Sell");
  };

  const placeOrder = () => {
    if (user && user.userId != undefined && user.userId > 0) {
      setIsOrderOpening(true);
      setActiveTab("open-orders-acc");
      placePendingOrder();
    }
  };

  const placePendingOrder = () => {
    if (orderEntryPrice > 0) {      
      if ((buyActive || sellActive) && orderQuantity > 0 && orderEntryPrice > 0) {
        // Calculate required margin based on trading logic
        const requiredMargin = calculateOrderRequiredMargin();
  
        // Check if the user has enough balance
        if (metrics.equity >= requiredMargin) {
          // Continue with opening the position
          openPendingOrder_api(requiredMargin);
        } else {
          // Show an alert for insufficient balance
          toast.error("Insufficient equity to open the order.", {
            position: "top-right",
          });
          document.getElementById("closeSound").play();
          setIsOrderOpening(false);
        }
      } else {
        toast.error("Order Quantity should be greater than 0.", {
          position: "top-right",
        });
        setIsOrderOpening(false);
      }
    }else{
      toast.error("Entry Price should be greater than 0.", {
        position: "top-right",
      });
      setIsOrderOpening(false);
    }
  };

  const calculateOrderRequiredMargin = () => {
    let reqMarginWithoutLeverage = orderQuantity * orderEntryPrice * selectedSymbolExchangeRate;

    if (
      platFromData[6] &&
      platFromData[6].availableLeverage &&
      Array.isArray(platFromData[6].availableLeverage) &&
      platFromData[6].availableLeverage.length > 0 &&
      platFromData[6].availableLeverage[0]?.available_leverage &&
      Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
      platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
      Array.isArray(platFromData[6].availableLeverage[0].available_leverage[0]?.group_level_leverage) &&
      platFromData[6].availableLeverage[0].available_leverage[0].group_level_leverage.length > 0
    ){
    
          let symbolLeverage = platFromData[6]?.availableLeverage[0]?.available_leverage[0].group_level_leverage.sort((a, b) => {
            const aLevel = a && a.exposure_level !== null && a.exposure_level !== undefined
              ? parseFloat(a.exposure_level)
              : Infinity;
            const bLevel = b && b.exposure_level !== null && b.exposure_level !== undefined
              ? parseFloat(b.exposure_level)
              : Infinity;
            return aLevel - bLevel;
          });
    
     let groupMinLeverage =symbolLeverage.filter(lev => {
        // Ensure `lev.exposure_level` exists and is a valid number before comparison
        const exposureLevel = lev && lev.exposure_level !== null && lev.exposure_level !== undefined
          ? parseFloat(lev.exposure_level)
          : NaN;
        return !isNaN(exposureLevel) && exposureLevel >= reqMarginWithoutLeverage;
      })
    
    
    
    
    let groupDefaultLeverage
    
    if(platFromData[6]?.availableLeverage[0]?.available_leverage[0].group_level_leverage.length > 0 && groupMinLeverage.length ==0){
    groupDefaultLeverage = symbolLeverage[symbolLeverage.length-1]
    }else{
    groupDefaultLeverage = groupMinLeverage[0];
    }
      
        let minimumLeverage = 1;
        if (platFromData[6]?.availableLeverage[0]?.available_leverage[0]) {
                minimumLeverage = getMinimumLeverage(
                  platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage,
                groupDefaultLeverage.max_leverage,
                []
            );
        }
        const lvg = minimumLeverage || 1;
        const converted_entry_price =  orderEntryPrice * selectedSymbolExchangeRate
        const requiredMargin = orderQuantity  * (converted_entry_price / lvg);
        return isNaN(requiredMargin)
        ? 0
        :requiredMargin<1?requiredMargin.toFixed(5)
        : Math.round(requiredMargin * 10) / 10
      } else if (
        platFromData[6] &&
        platFromData[6].availableLeverage &&
        Array.isArray(platFromData[6].availableLeverage) &&
        platFromData[6].availableLeverage.length > 0 &&
        platFromData[6].availableLeverage[0]?.available_leverage &&
        Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
        platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
        Array.isArray(platFromData[6].availableLeverage[0].available_leverage[0]?.symbol_default_leverage) &&
        platFromData[6].availableLeverage[0].available_leverage[0].symbol_default_leverage.length > 0
      ){
        let symbolLeverage = platFromData[6]?.availableLeverage[0]?.available_leverage[0].symbol_default_leverage.sort((a, b) => {
          // Handle missing or invalid `exposure_level` values using parseFloat
          const aLevel = a && a.exposure_level !== null && a.exposure_level !== undefined
            ? parseFloat(a.exposure_level)
            : Infinity;
          const bLevel = b && b.exposure_level !== null && b.exposure_level !== undefined
            ? parseFloat(b.exposure_level)
            : Infinity;
          return aLevel - bLevel;
        })
        
        let symbolMinLeverage= symbolLeverage.filter(lev => {
          // Ensure `lev.exposure_level` exists and is a valid number before comparison
          const exposureLevel = lev && lev.exposure_level !== null && lev.exposure_level !== undefined
            ? parseFloat(lev.exposure_level)
            : NaN;
          return !isNaN(exposureLevel) && exposureLevel >= reqMarginWithoutLeverage;
        })
    
      let symbolDefaultLeverage
    
      if(platFromData[6]?.availableLeverage[0]?.available_leverage[0].symbol_default_leverage.length > 0 && symbolMinLeverage.length ==0){
        symbolDefaultLeverage = symbolLeverage[symbolLeverage.length-1]
        }else{
        symbolDefaultLeverage = symbolMinLeverage[0];
        }
      
    let minimumLeverage = 1;
    if (platFromData[6]?.availableLeverage[0]?.available_leverage[0]) {
            minimumLeverage = getMinimumLeverage(
            platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage,
            [],
            symbolDefaultLeverage.max_leverage,
            
        );
    }
    const lvg = minimumLeverage || 1;
    const converted_entry_price =  orderEntryPrice * selectedSymbolExchangeRate
    const requiredMargin = orderQuantity  * (converted_entry_price / lvg);
    return isNaN(requiredMargin)
    ? 0
    :requiredMargin<1?requiredMargin.toFixed(5)
    : Math.round(requiredMargin * 10) / 10
      }else if (platFromData[6] &&
        platFromData[6].availableLeverage &&
        Array.isArray(platFromData[6].availableLeverage) &&
        platFromData[6].availableLeverage.length > 0 &&
        platFromData[6].availableLeverage[0]?.available_leverage &&
        Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
        platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
        platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage){

        const lvg = platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage || 1;
        console.log("minimumLeverage",lvg)
    const converted_entry_price =  orderEntryPrice * selectedSymbolExchangeRate
    const requiredMargin = orderQuantity  * (converted_entry_price / lvg);
        return isNaN(requiredMargin)
        ? 0
        :requiredMargin<1?requiredMargin.toFixed(5)
        : Math.round(requiredMargin * 10) / 10
    
      }
  };

  //#region API CALLS

  const openPendingOrder_api = async (margin) => {
    try {
      const currentDate = new Date();
      const currentDateTime = new Date(currentDate);

      const data: Order = {
        id: -1,
        created_at: null,
        symbol: selectedAuthSymbol,
        quantity: orderQuantity,
        amount: amount,
        asset_type: assetState.type,
        direction: direction,
        entry_price: orderEntryPrice,
        converted_entry_price: orderEntryPrice * selectedSymbolExchangeRate,
        TP: takeProfit,
        SL: stopLoss,
        netEUR: 0, // Set appropriate values
        status: "",
        quote_asset:symbolInfo.quote_asset,
        userId: user.userId,
        margin: margin,
        exit_price: 0,
        totalUnrealizedPnL: metrics.totalUnrealizedPnL,
        order_id: "OID" + Math.floor(100000 + Math.random() * 900000),
        status_updated_at: null,
        expires_at: expiresAt ? expiresAt : null,
        comment: orderComment,
        exchange_rate: selectedSymbolExchangeRate,
        lot_step: selectedLotStep,
        trade_type: symbolInfo.trade_type,
        lot_size: lotSize
      };

      const response = await APIMiddleware.post(API_ENDPOINT_OPEN_ORDER, data);

      const data_m = {
        ...response.data,
        created_at: new Date(response.data.created_at).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      // Show a success notification
      toast.success("Order opened successfully!", { position: "top-right" });
      document.getElementById("openSound").play();
      localStorage.accountManager = "open-orders-acc";
      setIsOrderOpening(false);
    } catch (error) {
      toast.error(error.response.data.error, { position: "top-right" });
      // Handle API request error
      console.error(`API request error: ${API_ENDPOINT_OPEN_ORDER}`, error);
      setIsOrderOpening(false);
    }
  };

  const handleLotStepOptionChange = (lotStep)=>{
    setSelectedLotStep(parseFloat(lotStep.value));
    setSelectedLotStepOption(lotStep);
  }

  const handleInputChange = (value) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');

  // Split the input into parts if multiple dots are present
  const floatValueParts = sanitizedValue.split('.');

  // Allow only one dot and concatenate valid parts
  const validValue = floatValueParts.length > 2
    ? `${floatValueParts[0]}.${floatValueParts[1]}`
    : sanitizedValue;

  // Update the state with the valid value
  setInputValue(validValue);
  };

  const handleQtyOptionChange = (option)=>{
    setOrderQuantity(parseFloat(option.value));
    setSelectedQtyOption(option);
  }

  const handleQtyInputChange = (value) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    const floatValueParts = sanitizedValue.split('.');
    // Allow only one dot and concatenate valid parts
    const validValue = floatValueParts.length > 2
      ? `${floatValueParts[0]}.${floatValueParts[1]}`
      : sanitizedValue;
    // Update the state with the valid value
    setInputQtyValue(validValue);
  };

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  return (
    <>
      <div className="price-box-container">
        <div
          className={`price-box price-box-buy ${
            buyActive ? "price-box-buy-active" : ""
          }`}
          id="buyMarketOrder"
          onClick={() => handleBuySellOrderClick(true)}
        >
          <div style={{fontSize: '14px', fontWeight: 'bolder'}}>Buy</div>
        </div>
        <div
          className={`price-box price-box-sell ${
            sellActive ? "price-box-sell-active" : ""
          }`}
          id="sellMarketOrder"
          onClick={() => handleBuySellOrderClick(false)}
        >
          <div style={{fontSize: '14px', fontWeight: 'bolder'}}>Sell</div>
        </div>
      </div>
      <div className="css-flex">
        <div className="box">
          <label htmlFor="stop-loss-market" className="order-label">
            Entry Price:
          </label>
          <input
            type="number"
            id="order-entry-price"
            className="order-input"
            value={orderEntryPrice}
            onChange={(e) => setOrderEntryPrice(parseFloat(e.target.value))}
          />
        </div>
        
{symbolInfo.trade_type === "units" ? (
  <>
    {assetState.type === "base_asset" ? (
      <div className="box">
        <label htmlFor="take-profit-market" className="order-label">
          Quantity:
        </label>
        <div className="lots-selector-container-order">
          <CreatableSelect
                styles={orderCustomStyles}
                onInputChange={handleQtyInputChange}
                inputValue={inputQtyValue}
                value={selectedQtyOption}
                onChange={handleQtyOptionChange}
                options={unitOptions}
                isSearchable
                placeholder="Select Lots"
                formatCreateLabel={formatCreateLabelQty}
                defaultInputValue={inputQtyValue}
                defaultValue={selectedQtyOption}
          />
        </div>
      </div>
    ) : (
      <div className="box">
        <label htmlFor="take-profit-market" className="order-label">
          Amount:
        </label>
        <input
          type="number"
          className="order-input"
          id="amount-input"
          value={amount}
          onChange={(e) => {
            setAmount(parseFloat(e.target.value));
          }}
        />
      </div>
    )}
    <div className="box">
      <label className="order-label ps-2">Type:</label>
      <Select
        options={[
          { value: symbolInfo.base_asset, label: symbolInfo.base_asset },
          { value: symbolInfo.quote_asset, label: symbolInfo.quote_asset },
        ]}
        value={{
          value: assetState.name,
          label: assetState.name,
        }}
        onChange={(selectedOption) => handleAssetChange(selectedOption)}
        styles={customStyle}
      />
    </div>
  </>
) : (
  <div className="box">
      <label className="order-label">Lots:</label>
      <div className="lots-selector-container-order">
          <CreatableSelect
            styles={customStyles}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            value={selectedLotStepOption}
            onChange={handleLotStepOptionChange}
            options={lotSteps}
            isSearchable
            placeholder="Select Lots"
            formatCreateLabel={formatCreateLabelLots}
            defaultInputValue={inputQtyValue}
            defaultValue={selectedQtyOption}
          />
      </div>
        </div>
)}
      </div>
      <div className="reuqired-margin">
        <div className="title">Required Margin</div>
        <div className="value">
          {calculateOrderRequiredMargin()}{" "}
          {user?.userCurrencyName ||"EUR"}
        </div>
      </div>
      {selectedOrderTab === "pending-order"?
      <SLTP isPendingOrder={true} isEditPosition={false} handleDisabledButton={handleDisabledButton} handleSLPipsChange={handleSLPipsChange} handleTPPipsChange={handleTPPipsChange} handleProfitChange={handleProfitChange} handleLossChange={handleLossChange} direction={direction} quantity={orderQuantity} entryPrice={orderEntryPrice} />
     : <></>}
      <div className="text-bx">
        <div className="text-area-letter-counter-sltp">
          <span id="count-comment-letters-po">0</span>/100
        </div>
        <textarea
          className="comment-sltp-lst"
          placeholder="Comment"
          onInput={(e) => {
            countLetters(e);
          }}
          value={orderComment}
          onChange={(e) => {
            setOrderComment(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="place-order">
        {/* Conditionally set the disabled attribute based on the user's authentication status */}
        <button
           title={
              
            platFromData[5]?.accessRight == 3
            ? "Trading for this Account in Disabled"
            :platFromData[5]?.accessRight == 2
              ? "The status of this account is set to Close Only . You can only close your existing Positions" 
              : ""
        } 
          disabled={platFromData[5]?.accessRight == 2 ||platFromData[5]?.accessRight == 3|| localStorage.getItem('accountType') =="0" ||!bidPrice ||!askPrice ||isOrderOpening}
          className={`place-order-button ${
            buyActive ? "place-order-button-buy" : "place-order-button-sell"
          }`}
          onClick={() => {
            placeOrder();
          }}
          style={{
            position: 'relative',
            overflow: 'hidden',
            isolation: 'isolate',
          }}
        >
          <Ripple/>
          <b style={{fontSize: '14px', fontWeight: 'bolder'}} >
            {!isOrderOpening ? direction : "Loading..."}
          </b>
          <br />
          <span style={{fontSize: '13px'}} >
            {orderQuantity || ""} {selectedAuthSymbol} @ {orderEntryPrice}
          </span>
        </button>
      </div>
    </>
  );
};

export default PendingOrder;
