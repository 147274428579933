import React, { useEffect, useRef, useState } from 'react';
import Spinner from '../../utils/spinner/Spinner';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5stock from '@amcharts/amcharts5/stock';
import am5themes_Dark from '@amcharts/amcharts5/themes/Material';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { API_ENDPOINT_KLINES_MT } from '../../../data/Endpoints-API';
import { useSymbolContext } from '../../../contexts/Symbol-Context';
import { useAuthContext } from '../../../contexts/Auth-Context';
import { useChartContext } from '../../../contexts/Chart-Context';
// import '../../../themes/old-dark.scss'
// import '../../../themes/dark.scss'
// import './Chart.scss';
import './Chart-Toolbar.scss';
import './am5stockChart.scss'

const Chart = ({ mobileMode }) => {
  // Context
  const {
    loadingSymbolContext,
    symbolNames,
    updateSymbolName,
    symbolInfo,
    updateSymbolDetailsData,
    setSelectedCategoryId,
    symbolWithCategories,
    setSelectedSymbolSession,
  } = useSymbolContext();
  const {
    user,
    platFromData,
    selectedAuthSymbol,
    selectedAuthSymbolId,
    setAuthSelectedSymbol,
    setAuthTimeFrame,
    setAuthSelectedCategory,
    defaultSelectedCategory,
  } = useAuthContext();
  const {
    selectedTimeFrame,
    updateChartTimeFrame,
    // selectedSeriesType,
    zoomRange,
    setLineCandle,
  } = useChartContext();

  const chartRef = useRef(null);
  const valueSeriesRef = useRef(null);
  const timeFrameChartSetup = 'minute';
  const numberFormat = '#,###.0####';

  useEffect(() => {
    if (selectedAuthSymbol !== '') {
      updateSymbolDetailsData(selectedAuthSymbol);
    }
  }, [selectedAuthSymbol]);

  //local states
  let [stockChart2, setStockChart2] = useState(null);
  let [currentLabel2, setCurrentLabel2] = useState(null);
  let [currentLabelNew2, setCurrentLabelNew2] = useState(null);
  let [currentValueDataItem2, setCurrentValueDataItem2] = useState(null);
  let [currentValueDataItemNew, setCurrentValueDataItemNew] = useState(null);


  let [dataReady, setDataReady] = useState(false);
  const allChartDataRef = useRef([]);
  const currentChartDataRef = useRef([]);

  let root = null;
  let currentLabel = null;
  let currentLabelNew = null;
  let currentValueDataItem = null;
  let candleLength = 150;

  // useLayoutEffect(() => {
  //   if (!loadingSymbolContext) {
  //     chartSetup();
  //   }
  // }, [loadingSymbolContext]);

  // useEffect to handle component lifecycle
  useEffect(() => {
    //nothing will load in this component until SymbolContext fully loaded
    if (!loadingSymbolContext) {
      chartSetup();
      return () => {
        root.dispose();
      };
    }
  }, [loadingSymbolContext, selectedAuthSymbol, selectedTimeFrame]);

  // useEffect(() => {
  //   if (
  //     !loadingSymbolContext &&
  //     chartRef?.current &&
  //     valueSeriesRef?.current &&
  //     chartLoadedFirstTime
  //   ) {
  //     debugger;
  //     loadChartData(valueSeriesRef?.current);
  //   }
  // }, [selectedAuthSymbol, selectedTimeFrame]);

  useEffect(() => {
    //nothing will load in this component until SymbolContext fully loaded
    if (!loadingSymbolContext && dataReady) {
      updateLiveFeedData();
    }
  }, [platFromData[1]], dataReady);

  //dispose root if there is any because only root could exists
  // const maybeDisposeRoot = (divId) => {
  //   am5.array.each(am5.registry.rootElements, function (root) {
  //     if (root.dom.id === divId) {
  //       root.dispose();
  //     }
  //   });
  // };

  // Main chart setup function
  const chartSetup = () => {
    //dispose root if there is any because only root could exists
    // maybeDisposeRoot('chartdiv');

    //#region CHART SETUP
    root = am5.Root.new('chartdiv');

    var indicator = root.container.children.push(am5.Container.new(root, {
      width: am5.p100,
      height: am5.p100,
      layer: 1000,
      background: am5.Rectangle.new(root, {
        fill: am5.color('#191919'),
        fillOpacity: 0.9
      })
    }));

    indicator.children.push(am5.Label.new(root, {
      text: "Loading...",
      fontSize: 25,
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      fill: am5.color(0x808080)
    }));
    let hourglass = indicator.children.push(am5.Graphics.new(root, {
      width: 32,
      height: 32,
      fill: am5.color(0x808080),
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      dy: -45,
      svgPath: "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z"
    }));
    var hourglassanimation = hourglass.animate({
      key: "rotation",
      to: 180,
      loops: Infinity,
      duration: 2000,
      easing: am5.ease.inOut(am5.ease.cubic)
    });


    hourglassanimation.play();
    indicator.show();

    // The following line removes the AMCharts logo even in the free version. BUT THIS IS AGAINST THEIR LICENSE AGREEMENT.
    root._logo.dispose();

    //custom theme
    const myTheme = am5.Theme.new(root);

    myTheme.rule('Grid').setAll({
      stroke: am5.color(0x808080),
      strokeWidth: 1,
    });

    myTheme.rule('Label').setAll({
      fill: am5.color('#fff'),
    });

    // Set themes
    root.setThemes(
      mobileMode
        ? [am5themes_Responsive.new(root), am5themes_Dark.new(root), myTheme]
        : [am5themes_Animated.new(root), am5themes_Dark.new(root), myTheme],
    );

    root.container.set("background", am5.Rectangle.new(root, {
      fill: am5.color('#191919') // Set your desired color here
    }));

    //#region Create a stock chart
    let stockChart = root.container.children.push(
      am5stock.StockChart.new(root, {
        stockNegativeColor: am5.color('rgb(245, 36, 36)'),
        stockPositiveColor: am5.color('rgb(17, 209, 97)'),
      }),
    );

    setStockChart2(stockChart);

    // Set global number format
    root.numberFormatter.set('numberFormat', numberFormat);

    // Create a main stock panel (chart)
    let mainPanel = stockChart.panels.push(
      am5stock.StockPanel.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      }),
    );

    // Create value/vertical axis
    let valueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        start: 0.9, //show 10% candles on load
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom',
          crisp: true,
          stroke: am5.color('#2d2d2d'),
          strokeOpacity: 1,
          strokeWidth: 1,
        }),
        extraMin: 0.1, // adds some space for for main series
        extraMax: 0.4, // adds some space for for main series
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: numberFormat,
        maxPrecision: 4,
        extraTooltipPrecision: 2,
      }),
    );

    // Create date/horizontal axis
    let dateAxis = mainPanel.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        start: zoomRange || 0.9, //show 10% candles on load
        minZoomCount: mobileMode ? 30 : 10,
        // maxZoomCount: 50,
        baseInterval: {
          timeUnit: timeFrameChartSetup,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          pan: 'zoom',
          stroke: am5.color('#2d2d2d'),
          strokeOpacity: 1,
          strokeWidth: 1,
          minGridDistance: mobileMode ? 30 : 50,
        }),
        crisp: true,
        tooltip: am5.Tooltip.new(root, {}),
        end: 1.1, // increase the end value to add space on the right side
      }),
    );

    // add range which will show current value
    currentValueDataItem = valueAxis.createAxisRange(
      valueAxis.makeDataItem({ value: 0 }),
    );

    currentValueDataItemNew = valueAxis.createAxisRange(
      valueAxis.makeDataItem({ value: 0 }),
    );
    setCurrentValueDataItemNew(currentValueDataItemNew);

    setCurrentValueDataItem2(currentValueDataItem);

    currentLabel = currentValueDataItem.get('label');
    currentLabelNew = currentValueDataItemNew.get('label');

    setCurrentLabelNew2(currentLabelNew);
    setCurrentLabel2(currentLabel);
    if (currentLabelNew) {
      currentLabelNew.setAll({
        fill: am5.color(0xffffff),
        background: am5.Rectangle.new(root, { fill: am5.color(0x000000) }),
      });
    }
    if (currentLabel) {
      currentLabel.setAll({
        fill: am5.color(0xffffff),
        background: am5.Rectangle.new(root, { fill: am5.color(0x000000) }),
      });
    }

    let currentGridNew = currentValueDataItemNew.get('grid');
    let currentGrid = currentValueDataItem.get('grid');

    if (currentGridNew) {
      currentGridNew.setAll({
        stroke: am5.color('rgb(17, 209, 97)'),
        strokeOpacity: 0.5,
        // strokeDasharray: [2, 5]
      });
    }

    if (currentGrid) {
      currentGrid.setAll({
        stroke: am5.color('rgb(245, 36, 36)'),
        strokeOpacity: 1,
      });
    }

    // Add series
    let valueSeries;
    let volumeSeries;
    var tooltip;

    valueSeries = mainPanel.series.push(
      am5xy.CandlestickSeries.new(root, {
        name: selectedAuthSymbol,
        clustered: false,
        valueXField: 'Date',
        valueYField: 'Close',
        highValueYField: 'High',
        lowValueYField: 'Low',
        openValueYField: 'Open',
        calculateAggregates: true,
        xAxis: dateAxis,
        yAxis: valueAxis,
        tooltip: tooltip,
      }),
    );

    // Set main value series
    stockChart.set('stockSeries', valueSeries);
    stockChart.set('volumeSeries', volumeSeries);

    // Add cursor(s)
    mainPanel.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        // behavior: "zoomXY",
        // behavior: "selectXY",
        yAxis: valueAxis,
        xAxis: dateAxis,
        //snapToSeries: [valueSeries],
        //snapToSeriesBy: "y!"
      }),
    );

    //#region Zoom Scrollbar Setup------------------------------------------------

    const bgColor = getComputedStyle(document.body).backgroundColor;

    var scrollbarX = am5xy.XYChartScrollbar.new(root, {
      orientation: 'horizontal',
      width: 100,
      x: am5.p100,
      centerX: am5.p100,
      dx: -65,
      y: 8,
      centerY: am5.p100,
      visible: !mobileMode,
    });

    scrollbarX.thumb.setAll({
      fill: bgColor,
      fillOpacity: 0,
    });

    scrollbarX.startGrip.setAll({
      visible: true,
      scale: 0.6,
    });

    scrollbarX.endGrip.setAll({
      visible: true,
      scale: 0.6,
    });

    scrollbarX.get('background').setAll({
      fill: bgColor,
      fillOpacity: 0,
      cornerRadiusTR: 100,
      cornerRadiusBR: 100,
      cornerRadiusTL: 100,
      cornerRadiusBL: 100,
    });

    mainPanel.set('scrollbarX', scrollbarX);

    //#endregion

    //#region Create volume axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true,
    });

    volumeAxisRenderer.labels.template.set('forceHidden', true);
    volumeAxisRenderer.grid.template.set('forceHidden', true);

    var volumeValueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: '#.#a',
        height: am5.percent(20),
        y: am5.percent(100),
        centerY: am5.percent(100),
        renderer: volumeAxisRenderer,
      }),
    );

    //#region Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    volumeSeries = mainPanel.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Volume',
        clustered: false,
        valueXField: 'Date',
        valueYField: 'Volume',
        xAxis: dateAxis,
        yAxis: volumeValueAxis,
        legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]",
      }),
    );

    volumeSeries.columns.template.setAll({
      strokeOpacity: 0,
      fillOpacity: 0.2,
    });

    // color columns by stock rules
    volumeSeries.columns.template.adapters.add('fill', function (fill, target) {
      return am5.color(
        getComputedStyle(document.querySelector('.left-nav')).color,
      );
    });

    // Set up symbol searchable drop down list
    let symbolSearchList = am5stock.DropdownListControl.new(root, {
      stockChart: stockChart,
      name: selectedAuthSymbol,
      fixedLabel: true,
      searchable: true,
      // items: symbolNames,
      items: symbolNames.map((symbolName) => ({
        id: symbolName,
        label: symbolName,
        className: symbolName === selectedAuthSymbol ? "selected-symbol" : ""
      })),
    });

    symbolSearchList.events.on('selected', function (ev) {
      // An item selected
      const matchingSymbol = symbolWithCategories.find(
        (symbol) => symbol.name === ev.item.label,
      );
      if (matchingSymbol) {
        setSelectedCategoryId(matchingSymbol.symbol_category);
        setAuthSelectedCategory(matchingSymbol.symbol_category);
        setSelectedSymbolSession(matchingSymbol.is_session_active);

        localStorage.setItem('symbol_id', matchingSymbol.id);
        localStorage.setItem('category', matchingSymbol.symbol_category);
      }
      updateSymbolName(ev.item.label);
      setAuthSelectedSymbol(ev.item.label);
    });

    //#region Series type switcher
    let seriesSwitcher = am5stock.SeriesTypeControl.new(root, {
      stockChart: stockChart,
    });

    seriesSwitcher.events.on('selected', function (ev) {
      newSetSeriesType(ev.item.id);
      setLineCandle(ev.item.id);
    });

    function getNewSettings(series) {
      let newSettings = {};
      am5.array.each(
        [
          'name',
          'valueYField',
          'highValueYField',
          'lowValueYField',
          'openValueYField',
          'calculateAggregates',
          'valueXField',
          'xAxis',
          'yAxis',
          'legendValueText',
          'stroke',
          'fill',
        ],
        function (setting) {
          newSettings[setting] = series.get(setting);
        },
      );
      return newSettings;
    }

    let wheelTimeout;
    mainPanel.events.on('wheelended', function () {
      if (wheelTimeout) {
        wheelTimeout.dispose(); // Clear previous timeout
      }

      wheelTimeout = mainPanel.setTimeout(function () {
        loadChartData(valueSeries, hourglassanimation, indicator, dateAxis, true);
      }, 50);  // Delay slightly to prevent triggering multiple calls
    });

    mainPanel.events.on('panended', function () {
      loadChartData(valueSeries, hourglassanimation, indicator, dateAxis, true);
    });

    function newSetSeriesType(seriesType) {
      // Get current series and its settings
      let currentSeries = stockChart.get('stockSeries');
      let newSettings = getNewSettings(currentSeries);

      // Remove previous series
      let data = currentSeries.data.values;
      mainPanel.series.removeValue(currentSeries);

      // Create new series
      let series;
      switch (seriesType) {
        case 'line': {
          series = mainPanel.series.push(
            am5xy.LineSeries.new(root, newSettings),
          );
          break;
        }
        case 'candlestick': {
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.CandlestickSeries.new(root, newSettings),
          );
          break;
        }
        case 'procandlestick': {
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.CandlestickSeries.new(root, newSettings),
          );
          if (seriesType == 'procandlestick') {
            series.columns.template.get('themeTags').push('pro');
          }
          break;
        }
        case 'ohlc': {
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.OHLCSeries.new(root, newSettings),
          );
          break;
        }
        default: {
          break;
        }
      }

      // Set new series as stockSeries
      if (series) {
        series.data.setAll(data);
        stockChart.set('stockSeries', series);
      }
    }

    //set intervals...
    let intervalSwitcher = am5stock.IntervalControl.new(root, {
      stockChart: stockChart,
      currentItem: selectedTimeFrame,
      items: [
        {
          id: '1m',
          label: '1m',
          className: `interval-selected-${selectedTimeFrame === '1m'}`,
          interval: { timeUnit: 'minute', count: 1 },
        },
        {
          id: '5m',
          label: '5m',
          className: `interval-selected-${selectedTimeFrame === '5m'}`,
          interval: { timeUnit: 'minute', count: 5 },
        },
        {
          id: '15m',
          label: '15m',
          className: `interval-selected-${selectedTimeFrame === '15m'}`,
          interval: { timeUnit: 'minute', count: 15 },
        },
        {
          id: '30m',
          label: '30m',
          className: `interval-selected-${selectedTimeFrame === '30m'}`,
          interval: { timeUnit: 'minute', count: 30 },
        },
        {
          id: '1h',
          label: '1h',
          className: `interval-selected-${selectedTimeFrame === '1h'}`,
          interval: { timeUnit: 'hour', count: 1 },
        },
        {
          id: '4h',
          label: '4h',
          className: `interval-selected-${selectedTimeFrame === '4h'}`,
          interval: { timeUnit: 'hour', count: 4 },
        },
        {
          id: '1d',
          label: '1d',
          className: `interval-selected-${selectedTimeFrame === '1d'}`,
          interval: { timeUnit: 'day', count: 1 },
        },
      ],
    });

    intervalSwitcher.events.on('selected', function (ev) {
      updateChartTimeFrame(ev.item.id);
      setAuthTimeFrame(ev.item.id);
      localStorage.setItem('timeFrame', ev.item.id);
    });

    // Stock toolbar
    am5stock.StockToolbar.new(root, {
      container: document.getElementById('chartcontrols'),
      stockChart: stockChart,
      controls: [
        symbolSearchList,
        seriesSwitcher,
        intervalSwitcher,
        am5stock.IndicatorControl.new(root, {
          stockChart: stockChart,
        }),
        am5stock.DrawingControl.new(root, {
          stockChart: stockChart,
        }),
        am5stock.ResetControl.new(root, {
          stockChart: stockChart,
        }),
        am5stock.SettingsControl.new(root, {
          stockChart: stockChart,
        }),
      ],
    });

    //#region Load initial data
    try {
      loadChartData(valueSeries, hourglassanimation, indicator);
    } catch (error) {
      console.error('Error loading chart data:', error);
      console.log(error);
    }

    chartRef.current = stockChart;
    valueSeriesRef.current = valueSeries;
  };

  const loadChartData = (series, hourglassanimation, indicator, dateAxis, isPanEnded = false) => {
    if (isPanEnded) {

      if (dateAxis._settings.start <= 0.01) {
        if (allChartDataRef.current.length > currentChartDataRef.current.length) {
          // Calculate how many more candles to load
          const remainingCandles = allChartDataRef.current.length - currentChartDataRef.current.length;
          const candlesToLoad = Math.min(remainingCandles, candleLength);

          const additionalCandles = allChartDataRef.current
            .slice(
              Math.max(0, allChartDataRef.current.length - currentChartDataRef.current.length - candlesToLoad),
              allChartDataRef.current.length - currentChartDataRef.current.length
            )
            // .filter(candle => candle.Date < currentChartData[0]?.Date); // Avoid duplicates
            ;

          let currChartData2 = additionalCandles.concat(currentChartDataRef.current);
          //Updating reference
          currentChartDataRef.current = currChartData2

          // Update the chart series
          series.data.setAll(currChartData2);
        }
      }

    } else {
      // If we don't have more data, call the API
      am5.net
        .load(
          API_ENDPOINT_KLINES_MT(
            selectedAuthSymbol,
            selectedTimeFrame,
            user?.userId,
            localStorage.getItem('symbol_id')
              ? localStorage.getItem('symbol_id')
              : selectedAuthSymbolId,
            localStorage.getItem('category')
              ? localStorage.getItem('category')
              : defaultSelectedCategory,
          ),
          series,
        )
        .then(function (result) {
          const res = am5.JSONParser.parse(result.response);
          const chartData = res.data.map((item) => {
            return {
              Date: new Date(item.time).getTime(),
              RealDate: new Date(item.time),
              Open: parseFloat(item.open),
              High: parseFloat(item.high),
              Low: parseFloat(item.low),
              Close: parseFloat(item.close),
              Volume: parseFloat(item.volume),
            };
          });


          // allChartData = chartData;
          allChartDataRef.current = chartData

          let currChartData = chartData.slice(
            Math.max(0, chartData.length - candleLength),
            chartData.length
          );

          currentChartDataRef.current = currChartData

          // Set Series data
          result.target.data.setAll(currChartData);
          // series.data.setAll(currentChartData);
          hourglassanimation.pause();
          indicator.hide();
          // dataReady = true;
          setDataReady(true);

        });

    }
  };

  // useEffect(() => {
  //   return () => {
  //     chartRef.current && chartRef.current.dispose();
  //   };
  // }, []);

  // update price chart with live feeds
  const updateLiveFeedData = () => {
    if (
      platFromData.length > 0 &&
      platFromData[1] &&
      platFromData[1] != undefined &&
      platFromData[1] != 'undefined' &&
      stockChart2 != null &&
      currentLabel2 != null &&
      currentValueDataItem2 != null &&
      platFromData[1].s == selectedAuthSymbol
    ) {
      // Create a new data point based on the live feed data
      const liveFeedData = {
        Date: new Date(platFromData[1].t).getTime(), // Assuming the timestamp is in milliseconds
        Open: parseFloat(platFromData[1].o),
        High: parseFloat(platFromData[1].h),
        Low: parseFloat(platFromData[1].l),
        Close: parseFloat(platFromData[1].c),
        Volume: parseFloat(platFromData[1].v),
        askPrice: parseFloat(platFromData[1].ask),
      };
      // Get the series for candlestick chart
      // console.log('valueSeries', stockChart2);
      let valueSeries = stockChart2.get('stockSeries');
      // console.log("st", valueSeries, liveFeedData);

      // Get the current timestamp
      let date = liveFeedData.Date; //Date.now();

      // Variable to store the new value for the live feed
      let value;
      let volume;

      // Get the last data object in the series
      let lastDataObject = valueSeries.data.getIndex(
        valueSeries.data.length - 1,
      );
      if (lastDataObject) {
        // Get the previous date and closing value from the last data object
        let previousDate = lastDataObject.Date;
        let previousValue = lastDataObject.Close;

        // set live price value
        value = liveFeedData.Close;

        volume = liveFeedData.Volume;

        // Get the high, low, and open values from the last data object
        let high = lastDataObject.High;
        let low = lastDataObject.Low;
        let open = lastDataObject.Open;

        // Check if a minute has passed since the previous data point
        if (am5.time.checkChange(date, previousDate, timeFrameChartSetup)) {
          open = value;
          high = value;
          low = value;

          let dObj1 = {
            Date: date,
            Close: value,
            Open: value,
            Low: value,
            High: value,
            Volume: volume,
          };

          // Add the new data object to the series
          valueSeries.data.push(dObj1);

          previousDate = date;

          currentChartDataRef.current.push(dObj1)

        } else {
          if (value > high) {
            high = value;
          }
          if (value < low) {
            low = value;
          }

          // Update the existing data object for the current minute
          let dObj2 = {
            Date: date,
            Close: value,
            Open: open,
            Low: low,
            High: high,
            Volume: volume,
          };

          // Replace the last data object in the series with the updated data
          valueSeries.data.setIndex(valueSeries.data.length - 1, dObj2);
        }
        // Update the live data label and animation if available
        if (currentLabel2) {
          currentValueDataItem2.set('value', value);
          // currentValueDataItemNew.set('value', liveFeedData.askPrice)
          currentValueDataItemNew.set('value', liveFeedData.askPrice);
          currentLabel2.set(
            'text',
            // formatPriceUptoDecimals(value, symbolInfo.digit),
            parseFloat(value).toFixed(symbolInfo?.digit || 2),
          );
          currentLabelNew2.set(
            'text',
            parseFloat(liveFeedData.askPrice).toFixed(symbolInfo?.digit || 2),
            // formatPriceUptoDecimals(liveFeedData.askPrice, symbolInfo.digit),
          );
          let bg = currentLabel2.get('background');
          let bg2 = currentLabelNew2.get('background');
          if (bg) {
            bg.set('fill', am5.color('rgb(245, 36, 36)'));
          }
          if (bg2) {
            bg2.set('fill', am5.color('rgb(17, 209, 97)'));
          }
        }
      }
    }
  };

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  return (
    <div className="Chart global-platfrom">
      <div id="chartcontrols"></div>
      <div id="chartdiv"></div>
    </div>
  );
};

export default Chart;
