import React, { useState, useEffect } from "react";
import Spinner from "../../utils/spinner/Spinner";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import "./Order-Panel.scss";
import { BsArrowUpSquare, BsArrowDownSquare } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import Select from "react-select";
import MarketOrder from "../market_order/Market-Order.tsx";
import NotificationsDropdown from "../notifications/NotificationDropdown.jsx"
import PendingOrder from "../pending_order/Pending-Order.tsx";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import UTCTimeConverter from "./UTCTimeConverter.jsx";
import { useMetricsContext } from '../../../contexts/Metrics-Context';


import { Ripple } from 'react-ripple-click';
import 'react-ripple-click/dist/index.css';

// Developed explicit Component for showing ask and bid price in the select symbol option
// import { components } from 'react-select';
// import { formatPrice } from "../../../utils/format.js";
import ServerTime from "./ServerTime.jsx";
import BotPanel from "../bot_panel/BotPanel";
import { RiRobot2Line } from "react-icons/ri";
import ReactSelect from "./React-Select.jsx";

// const CustomOption = ({ innerRef, innerProps, data }) => {
//   const [prevBid, setPrevBid] = useState(data.bid);
//   const [prevAsk, setPrevAsk] = useState(data.ask);
//   const [bidColor, setBidColor] = useState('#2ecc71'); // Initial color green
//   const [askColor, setAskColor] = useState('#2ecc71'); // Initial color green

//   useEffect(() => {
//     if (data.bid !== prevBid) {
//       setBidColor(data.bid > prevBid ? '#2ecc71' : '#e74c3c');
//       setPrevBid(data.bid);
//     }

//     if (data.ask !== prevAsk) {
//       setAskColor(data.ask > prevAsk ? '#2ecc71' : '#e74c3c');
//       setPrevAsk(data.ask);
//     }
//   }, [data.bid, data.ask, prevBid, prevAsk]);

//   // const formatPrice = (price) => {
//   //   if (price === undefined) return "";
//   //   const formattedPrice = price.toFixed(4);
//   //   const [whole, fractional] = formattedPrice.split('.');
//   //   const displayedFractional = fractional.length > 3 ? fractional.slice(0, 3) : fractional;
//   //   return (
//   //     <span>
//   //       {whole}.
//   //       <span className="fractional">{displayedFractional}</span>
//   //     </span>
//   //   );
//   // };

//   return (
//     <div ref={innerRef} {...innerProps} className="custom-option">
//       <div className="symbol" style={{ color: '#ffffff' }}>{data.label}</div>
//       <div className="bid" style={{ color: bidColor }}>
//         {formatPrice(data.bid)}
//       </div>
//       <div className="ask" style={{ color: askColor }}>
//         {formatPrice(data.ask)}
//       </div>
//     </div>
//   );
// };

// const CustomMenuList = (props) => {
//   return (
//     <components.MenuList {...props}>
//       <div className="header">
//         <div className="symbol-title">Symbol</div>
//         <div className="bid-title">Bid</div>
//         <div className="ask-title">Ask</div>
//       </div>
//       {props.children}
//     </components.MenuList>
//   );
// };


// Actual code of Order panel starts from here 

const OrderPanel: React.FC = () => {
  let prePositionMarkerState = 'show';
  if (localStorage.positionMarker !== undefined) {
    prePositionMarkerState = localStorage.positionMarker;
  } else {
    localStorage.setItem('positionMarker', prePositionMarkerState);
  }
  const [positionMarker, setPositionMarker] = useState(prePositionMarkerState);
  const setPositionMarkerValue = (e) => {
    setPositionMarker(e);
    localStorage.setItem('positionMarker', e);
  }
  const fontSelecter = (e) => {
    setFont(e.target.value);
    localStorage.setItem("storedFont", e.target.value);
    fontAdjuster(e);
  };


  //CONTEXT
  const {
    loadingSymbolContext,

    symbolInfo,
    marketHours,
    // symbolData,
    // updateSymbolData,
    // allSymbolOptions,
    // selectedSymbolOption,
    // handleSymbolOptionChange,
    leverage,
    hideLeverageCard,
    activeLvg
  } = useSymbolContext();
  // console.log(allSymbolOptions)
  const { platFromData, selectedAuthSymbol } =
    useAuthContext();
  const { fontAdjuster, fontUpdating } = useAuthContext();
  let toggleTooltipLocal;
  if (localStorage.chartTooltips !== undefined) {
    toggleTooltipLocal = localStorage.chartTooltips;
  } else {
    localStorage.setItem('chartTooltips', 'show');
    toggleTooltipLocal = 'show';
  }
  const [toggleTooltip, setToggleTooltip] = useState(toggleTooltipLocal);
  const [font, setFont] = useState(localStorage.getItem('storedFont') || 'Large');
  const [mobileMode, setMobileMode] = useState<boolean>(false);
  const largeRef = React.useRef();

  // bot is subscribed or not
  const subscribed = platFromData[7]['is_bot_subscribed'] != undefined ? platFromData[7]['is_bot_subscribed'] == 1 ? true : false : false;

  const resetChanges = () => {
    const c: boolean = window.confirm(
      "Are you sure you want to reset all the changes from this browser?"
    );
    if (c) {
      const makeSure = () => {
        for (let i = 0; i < localStorage.length; i++) {
          if (
            localStorage.key(i) !== "userId" &&
            localStorage.key(i) !== "userData" &&
            localStorage.key(i) !== "variantId"
          ) {
            localStorage.removeItem(localStorage.key(i));
            if (localStorage.length !== 1) {
              makeSure();
              window.location.reload();
            }
          }
        }
      };
      makeSure();
    }
  };
  //LOcal States
  const [activeMode, setActiveMode] = useState("order");
  const [selectedTab, setSelectedTab] = useState("market");
  useEffect(() => {
    if (localStorage.getItem("activeMode") != null) {
      setActiveMode(localStorage.getItem("activeMode"));
    }
    if (localStorage.getItem("orderTab") != null) {
      setSelectedTab(localStorage.getItem("orderTab"));
    }
  }, [localStorage.getItem("activeMode"), localStorage.getItem("orderTab"), localStorage.getItem("direction")]);

  const getMinimumLeverage = (userLeverage, groupLeverage, symbolLeverage) => {

    const parsedUserLeverage = parseFloat(userLeverage);
    const parsedGroupLeverage = parseFloat(groupLeverage);
    const parsedSymbolLeverage = parseFloat(symbolLeverage);
    // If groupLeverage is defined and not NaN
    if (groupLeverage !== undefined && groupLeverage !== null && groupLeverage !== 'null' && !isNaN(parsedGroupLeverage)) {
      return Math.min(parsedUserLeverage, parsedGroupLeverage);
    }

    // If symbolLeverage is defined and not NaN
    if (symbolLeverage !== undefined && symbolLeverage !== null && symbolLeverage !== 'null' && !isNaN(parsedSymbolLeverage)) {
      return Math.min(parsedUserLeverage, parsedSymbolLeverage);
    }

    // Default to userLeverage or 1 if userLeverage is NaN
    return isNaN(parsedUserLeverage) ? 1 : parsedUserLeverage;
  };
  const {
    currentUTCDate,
    setCurrentUTCDate,
    utcOffset,
    setUTCOffset,
    formatTime,
    utcOffsetInitial,
  } = useMetricsContext();
  useEffect(() => {
    const interval = setInterval(() => {
      const adjustedDate = new Date();
      const [hoursStr, minutesStr] = utcOffset.split(':');
      const hoursOffset = parseInt(hoursStr, 10) || 0;
      const minutesOffset = parseInt(minutesStr, 10) || 0;

      adjustedDate.setUTCHours(
        adjustedDate.getUTCHours() + hoursOffset,
        adjustedDate.getUTCMinutes() + minutesOffset,
      );

      setCurrentUTCDate(adjustedDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [utcOffset]);

  const handleChangeUTCOffset = (selectedOption) => {
    localStorage.setItem('utc_time', selectedOption.value);
    if (selectedOption) {
      setUTCOffset(selectedOption.value);
    }
  };

  const options = [
    { value: '-12:00', label: 'UTC-12:00' },
    { value: '-11:30', label: 'UTC-11:30' },
    { value: '-11:00', label: 'UTC-11:00' },
    { value: '-10:30', label: 'UTC-10:30' },
    { value: '-10:00', label: 'UTC-10:00' },
    { value: '-09:30', label: 'UTC-9:30' },
    { value: '-09:00', label: 'UTC-9:00' },
    { value: '-08:30', label: 'UTC-8:30' },
    { value: '-08:00', label: 'UTC-8:00' },
    { value: '-07:30', label: 'UTC-7:30' },
    { value: '-07:00', label: 'UTC-7:00' },
    { value: '-06:30', label: 'UTC-6:30' },
    { value: '-06:00', label: 'UTC-6:00' },
    { value: '-05:45', label: 'UTC-5:45' },
    { value: '-05:30', label: 'UTC-5:30' },
    { value: '-05:00', label: 'UTC-5:00' },
    { value: '-04:30', label: 'UTC-4:30' },
    { value: '-04:00', label: 'UTC-4:00' },
    { value: '-03:30', label: 'UTC-3:30' },
    { value: '-03:00', label: 'UTC-3:00' },
    { value: '-02:30', label: 'UTC-2:30' },
    { value: '-02:00', label: 'UTC-2:00' },
    { value: '-01:30', label: 'UTC-1:30' },
    { value: '-01:00', label: 'UTC-1:00' },
    { value: '+00:00', label: 'UTC+0' },
    { value: '+01:00', label: 'UTC+1:00' },
    { value: '+02:00', label: 'UTC+2:00' },
    { value: '+02:30', label: 'UTC+2:30' },
    { value: '+03:00', label: 'UTC+3:00' },
    { value: '+03:30', label: 'UTC+3:30' },
    { value: '+04:00', label: 'UTC+4:00' },
    { value: '+04:30', label: 'UTC+4:30' },
    { value: '+05:00', label: 'UTC+5:00' },
    { value: '+05:30', label: 'UTC+5:30' },
    { value: '+05:45', label: 'UTC+5:45' },
    { value: '+06:00', label: 'UTC+6:00' },
    { value: '+06:30', label: 'UTC+6:30' },
    { value: '+07:00', label: 'UTC+7:00' },
    { value: '+07:30', label: 'UTC+7:30' },
    { value: '+08:00', label: 'UTC+8:00' },
    { value: '+08:30', label: 'UTC+8:45' },
    { value: '+09:00', label: 'UTC+9:00' },
    { value: '+09:30', label: 'UTC+9:30' },
    { value: '+10:00', label: 'UTC+10:00' },
    { value: '+10:30', label: 'UTC+10:30' },
    { value: '+11:00', label: 'UTC+11:00' },
    { value: '+11:30', label: 'UTC+11:30' },
    { value: '+12:00', label: 'UTC+12:00' },
    { value: '+13:00', label: 'UTC+13:00' },
    { value: '+14:00', label: 'UTC+14:00' },
  ];
  const orderCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '15px !important',
      width: '100px',
      borderColor: '#484848 !important',
      backgroundColor: '#232323 !important',
      boxShadow: state.isFocused
        ? '0 0 50px #484848 !important'
        : provided.boxShadow,
      cursor: 'pointer',
      '&:hover': {
        borderColor: 'rgb(33, 196, 109) !important',
        cursor: 'pointer',
        boxShadow: '0 0 0 transparent !important',
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: '10px !important',
      borderColor: state.isFocused
        ? 'rgb(33, 196, 109) !important'
        : '#484848 !important',
      color: '#c5c5c5 !important',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: '#484848 !important',
      borderColor: state.isFocused
        ? 'rgb(33, 196, 109) !important'
        : '#484848 !important',
      color: '#484848 !important',
    }),

    option: (provided, state, inSettings = true) => ({
      ...provided,
      padding: inSettings ? '0.3rem !important' : '0 !important',
      cursor: 'pointer',
      color: state.isSelected
        ? 'rgb(33, 196, 109) !important'
        : '#c5c5c5 !important',

      borderBottom: '1px solid #232323',
      backgroundColor: state.isSelected ? '#232323 !important' : 'transparent',

      '&:hover': {
        backgroundColor: 'rgb(33, 196, 109) !important',
        color: '#2d2d2d !important',
      },
      '&:nth-last-child(1)': {
        borderBottom: 'none !important',
      },
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: '#3b3a3a !important',
      width: 'calc(100% - 1.6rem) !important',
      margin: '0.3rem 0.8rem 0 0.8rem !important',
      zIndex: 111,
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: '130px',
      overflowY: 'auto',
    }),
  };


  useEffect(() => {
    // TODO: use an alternative to useRef
    if (largeRef.current && localStorage.getItem('storedFont') == null) {
      const tempObj = { target: { value: 'Large' } };
      // setFont('Large');
      localStorage.setItem("storedFont", 'Large');
      fontAdjuster(tempObj);
    }
  }, [largeRef.current]);


  useEffect(() => {
    const checkMobileMode = () => {
      const isMobile = window.innerWidth <= 960;
      setMobileMode(isMobile);
    };

    // Initial check
    checkMobileMode();

    // Add event listener for window resize
    window.addEventListener('resize', checkMobileMode);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', checkMobileMode);
    };
  }, []);

  const [collapseState, setCollapseState] = useState({
    orders: true,
    symbols: false,
    market_hours: false,
    leverage: false,
  });

  // Determine classes based on activeMode
  const orderPanelClass = activeMode === "order" ? "visible" : "hidden";
  const settingsClass = activeMode === "settings" ? "visible" : "hidden";

  const handleModeClick = (mode: string) => {
    setActiveMode(mode);
    localStorage.setItem("activeMode", mode);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    localStorage.setItem("orderTab", tab);
  };

  useEffect(() => {
    if (!subscribed) {
      handleTabClick('market');
    }
  }, [subscribed]);

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  const handleCollapseClick = (section) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const orderStyles = {
    container: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center',
    }),
    control: (provided, state) => {
      return {
        ...provided,
        paddingLeft: '10px !important',
        boxShadow: state.isFocused ? '0 0 50px #484848 !important' : provided.boxShadow,
        '&:hover': {
          borderColor: 'rgb(33, 196, 109) !important',
          cursor: 'pointer',
          boxShadow: '0 0 0 transparent !important',
        },
        backgroundColor: '#232323 !important',
        borderColor: '#484848 !important',
        width: 'calc(100% - 0.9rem) !important',
        cursor: 'pointer',
        margin: '0 auto !important',
      };
    },
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: '10px !important',
      borderColor: state.isFocused ? 'rgb(33, 196, 109) !important' : '#484848 !important',
      color: '#c5c5c5 !important',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: '#484848 !important',
      borderColor: state.isFocused ? 'rgb(33, 196, 109) !important' : '#484848 !important',
      color: '#484848 !important',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '0.3rem !important',
      cursor: 'pointer',
      borderBottom: '1px solid #232323',
      '&:nth-last-child(1)': {
        borderBottom: 'none !important',
      },
      '&:hover': {
        backgroundColor: 'rgb(33, 196, 109) !important',
        color: '#2d2d2d !important',
      },
      backgroundColor: state.isSelected ? '#232323 !important' : 'transparent',
      color: state.isSelected ? 'rgb(33, 196, 109) !important' : '#c5c5c5 !important',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#3b3a3a !important',
      zIndex: 111,
      width: 'calc(100% - 2.25rem) !important',
      margin: '0 auto !important',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '50vh',
      overflowY: 'auto',
      padding: '20px !important'
    }),
  };
  return (
    <div className="global-platfrom">
      <div className="global-platform-order-child">
        <div className="mode-tabs" >
          <button
            className={`mode-tab-button ${activeMode === "order" ? "mode-tab-active" : ""
              }`}
            onClick={() => {
              handleModeClick("order");
            }}
            style={{
              position: 'relative',
              overflow: 'hidden',
              isolation: 'isolate',
            }}
          >
            <Ripple/>
            Order
          </button>
          <button
            className={`mode-tab-button ${activeMode === "settings" ? "mode-tab-active" : ""
              }`}
            onClick={() => {
              handleModeClick("settings");
            }}
            style={{
              position: 'relative',
              overflow: 'hidden',
              isolation: 'isolate',
            }}
          >
            <Ripple />
            Settings
          </button>
          {!mobileMode && <NotificationsDropdown />}
        </div>
        <div className={`order-panel ${orderPanelClass}`}>
          <div className="symbol-selector-container">
            <ReactSelect/>
            {/* <Select className="testclass100"
              value={selectedSymbolOption}
              onChange={handleSymbolOptionChange}
              options={allSymbolOptions}
              isSearchable
              placeholder="Select Symbol"
              components={{ Option: CustomOption, MenuList: CustomMenuList }}
            /> */}
          </div>
          <div
            className="collapse-card-header"
            onClick={() => handleCollapseClick("orders")}
          >
            <label htmlFor="text">New Order</label>
            <div className="arrow-icons">
              <BsArrowUpSquare
                className={`${collapseState["orders"] ? "visible" : "hidden"}`}
              />
              <BsArrowDownSquare
                className={`${!collapseState["orders"] ? "visible" : "hidden"}`}
              />
            </div>
          </div>
          <div
            className={`collapse-card-container`}
            role={`${collapseState["orders"] ? "visible" : "hidden"}`}
          >
            <div className="collapse-card-wrapper">
              <div className="flex-container">
                <div>{selectedAuthSymbol}</div>
                {platFromData[1]?.dailyChange && (
                  <div className={parseFloat(platFromData[1].dailyChange.split('(')[0]) >= 0 ? 'positive' : 'negative'}>
                    {platFromData[1]?.dailyChange}
                  </div>
                )}
              </div>




              <div className="order-tabs">
                <button
                  className={`tab-button ${selectedTab === "market" ? "order-tab-active" : ""
                    }`}
                  onClick={() => {
                    handleTabClick("market");
                  }}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    isolation: 'isolate',
                  }}
                >
                  <Ripple />
                  Market
                </button>
                <button
                  className={`tab-button ${selectedTab === "pending-order" ? "order-tab-active" : ""
                    }`}
                  onClick={() => {
                    handleTabClick("pending-order");
                  }}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    isolation: 'isolate',
                  }}
                >
                  <Ripple />
                  Pending Order
                </button>
                {subscribed && (
                <button
                  className={`tab-button ${selectedTab === "bot-trading" ? "order-tab-active" : ""
                    }`}
                  onClick={() => {
                    handleTabClick("bot-trading");
                    // mkRipple(e);
                  }}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    isolation: 'isolate',
                  }}
                >
                  <Ripple />
                  <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '0.5rem',
                  }}>
                    <span>
                      iBot
                    </span>
                    <RiRobot2Line height={14} />
                  </span>
                </button>
                )}
              </div>
              <div
                className={`order-section ${selectedTab === "market" ? "visible" : "hidden"
                  }`}
                id="market"
              >

                <MarketOrder selectedOrderTab={selectedTab} />
              </div>
              <div
                className={`order-section ${selectedTab === "pending-order" ? "visible" : "hidden"
                  }`}
                id="pending"
              >
                <PendingOrder selectedOrderTab={selectedTab} />
              </div>
              {subscribed && (
              <div
                className={`order-section ${selectedTab === "bot-trading" ? "visible" : "hidden"
                  }`}
                id="bot"
              >
                <BotPanel selectedOrderTab={selectedTab} />
              </div>
              )}
            </div>
          </div>

          {/* Symbols Info */}
          <div
            className="collapse-card-header"
            onClick={() => handleCollapseClick("symbols")}
          >
            <label htmlFor="text">Symbol Info</label>
            <div className="arrow-icons">
              <BsArrowUpSquare
                className={`${collapseState["symbols"] ? "visible" : "hidden"}`}
              />
              <BsArrowDownSquare
                className={`${!collapseState["symbols"] ? "visible" : "hidden"}`}
              />
            </div>
          </div>
          <div
            className={`collapse-card-container`}
            role={`${collapseState["symbols"] ? "visible" : "hidden"}`}
          >
            <div className="collapse-card-wrapper">
              <div className="symbol-info-panel">
                <ul className="symbol-info-list">
                  <li>
                    <strong>Pip Position</strong>{" "}
                    <span>{symbolInfo?.pip_position}</span>
                  </li>
                  <li>
                    <strong>Lot Size</strong> <span>{symbolInfo?.lot_size}</span>
                  </li>
                  <li>
                    <strong>Swap (long)</strong>{" "}
                    <span>{parseInt(symbolInfo?.swap_long) || '\u2014'}</span>
                  </li>
                  <li>
                    <strong>Swap (short)</strong>{" "}
                    <span>{parseInt(symbolInfo?.swap_short) || '\u2014'}</span>
                  </li>
                  <li>
                    <strong>3-day swaps</strong>{" "}
                    <span>{parseInt(symbolInfo?.three_day_swaps) || '\u2014'}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Markets sections */}
          {
          false && (<div
            className="collapse-card-header"
            onClick={() => handleCollapseClick("market_hours")}
          >
            <label htmlFor="text">Market Hours</label>
            <div className="arrow-icons">
              <BsArrowUpSquare
                className={`${collapseState["market_hours"] ? "visible" : "hidden"
                  }`}
              />
              <BsArrowDownSquare
                className={`${!collapseState["market_hours"] ? "visible" : "hidden"
                  }`}
              />
            </div>
          </div>)
          }
          <div
            className={`collapse-card-container`}
            role={`${collapseState["market_hours"] ? "visible" : "hidden"}`}
          >
            <div className="collapse-card-wrapper">
              <div className="market-hours-panel">
                <ul className="market-hours-list">
                  {Array.isArray(marketHours) &&
                    marketHours.map((market_hours, index) => {
                      return (
                        <li key={index}>
                          <strong>
                            {market_hours.start_day} {market_hours.start_time} -{" "}
                            {market_hours.end_time}
                          </strong>{" "}
                          <span>
                            <GoDotFill className={market_hours.is_active == true ? 'svg-green' : 'svg-red'}/>
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          {/* leverage sections */}
          <div>
            {leverage && leverage.length > 0 && hideLeverageCard && (
              <div>
                <div
                  className="collapse-card-header"
                  onClick={() => handleCollapseClick("leverage")}
                >
                  <label htmlFor="text">Leverage</label>
                  <div className="arrow-icons">
                    <BsArrowUpSquare
                      className={`${collapseState["leverage"] ? "visible" : "hidden"
                        }`}
                    />
                    <BsArrowDownSquare
                      className={`${!collapseState["leverage"] ? "visible" : "hidden"
                        }`}
                    />
                  </div>
                </div>
                <div
                  className={`collapse-card-container`}
                  role={`${collapseState["leverage"] ? "visible" : "hidden"}`}
                >
                  <div className="collapse-card-wrapper">
                    <div className="leverage-panel">
                      <ul className="leverage-list">
                        <li className="leverage-heading">
                          <strong>Volume</strong>
                          <strong>Leverage</strong>
                          <strong>Status</strong>
                        </li>
                        {leverage && Array.isArray(leverage[0]?.group_level_leverage) && leverage[0]?.group_level_leverage.length > 0 ? (
                          (() => {
                            // Check if activeLvg matches all getMinLvgVar in group_level_leverage
                            const allMatch = leverage[0].group_level_leverage.every(lvg => {
                              const minLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, lvg.max_leverage, []);
                              return activeLvg === minLvgVar;
                            });

                            return leverage[0]?.group_level_leverage.map((lvg, index) => {
                              const isLast = index === leverage[0].group_level_leverage.length - 1;
                              const getMinLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, lvg.max_leverage, []);

                              return (
                                <li key={index}>
                                  <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="marginRight" style={{ textAlign: 'center', fontSize: '1.5em' }}>{isLast ? '∞' : '≤'}</span>
                                    <span style={{ textAlign: 'center', fontSize: '1.0em' }}>{lvg.exposure_level}</span>
                                  </span>
                                  <span>1:{getMinLvgVar}</span>{" "}
                                  <span>
                                    <GoDotFill className={
                                      allMatch && isLast
                                        ? 'svg-green'
                                        : activeLvg == getMinLvgVar && !allMatch
                                          ? 'svg-green'
                                          : 'svg-red'
                                    } />
                                  </span>
                                </li>
                              );
                            });
                          })()
                        )
                          : leverage && Array.isArray(leverage[0]?.symbol_default_leverage) && leverage[0]?.symbol_default_leverage.length > 0 ? (
                            (() => {
                              // Check if activeLvg matches all getMinLvgVar in symbol_default_leverage
                              const allMatch = leverage[0].symbol_default_leverage.every(lvg => {
                                const minLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, [], lvg.max_leverage);
                                return activeLvg === minLvgVar;
                              });

                              return leverage[0]?.symbol_default_leverage.map((lvg, index) => {
                                const isLast = index === leverage[0].symbol_default_leverage.length - 1;
                                const getMinLvgVar = getMinimumLeverage(leverage[0].user_default_leverage, [], lvg.max_leverage);

                                return (
                                  <li key={index}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      <span className="marginRight" style={{ textAlign: 'center', fontSize: '1.5em' }}>{isLast ? '∞' : '≤'}</span>
                                      <span style={{ textAlign: 'center', fontSize: '1.0em' }}>{lvg.exposure_level}</span>
                                    </span>
                                    <span>1:{getMinLvgVar}</span>{" "}
                                    <span>
                                      <GoDotFill className={
                                        allMatch && isLast
                                          ? 'svg-green'
                                          : activeLvg == getMinLvgVar && !allMatch
                                            ? 'svg-green'
                                            : 'svg-red'
                                      } />
                                    </span>
                                  </li>
                                );
                              });
                            })()
                          ) : <li >
                            <span> </span>{" "}
                            <span>1:{leverage[0].user_default_leverage} </span>
                            <span><GoDotFill className={activeLvg == parseInt(leverage[0].user_default_leverage) ? 'svg-green' : 'svg-red'} /></span>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`settings-right-panel ${settingsClass}`}>
          <div className="main-row">
            <div className="font-adjuster -setting-panel-divider header-select-container" >
                <h2>Server & Current Time Info</h2>
                <Select
                  styles={orderCustomStyles}
                  value={{
                    value: utcOffset,
                    label: `UTC ${utcOffset >= 0 ? '+' : ''}${utcOffset}`,
                  }}
                  onChange={handleChangeUTCOffset}
                  options={options}
                  menuPlacement="auto"
                />
              </div>
            <div className="font-adjuster -setting-panel-divider">
              <UTCTimeConverter />
            </div>
            <div className="font-adjuster -setting-panel-divider" style={{borderBottom:"1px solid #232323"}}>
              <ServerTime />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPanel; 