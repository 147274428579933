import React, { useEffect, useState } from 'react'
import Spinner from '../../utils/spinner/Spinner'
import { FaSearch, FaTimes } from 'react-icons/fa';
import { WS_ENDPOINT_PLATFORM_LIVE_FEEDS, WS_ENDPOINT_SYMBOLS_LIVE_FEEDS } from "../../../data/Endpoints-WS";
import { ws_create } from "../../../data/websocket/Websocket-Middleware";
import { useSymbolContext } from "../../../contexts/Symbol-Context";
import { useAuthContext } from '../../../contexts/Auth-Context';
import { useChartContext } from '../../../contexts/Chart-Context';
import { API_ENDPOINT_SYMBOLS } from '../../../data/Endpoints-API';
import { API_ENDPOINT_USER_WATCHLIST } from '../../../data/Endpoints-API';
import { API_ENDPOINT_INSERT_WATCHLIST } from '../../../data/Endpoints-API';
import { API_ENDPOINT_DELETE_WATCHLIST } from '../../../data/Endpoints-API';
import './Symbol.scss'
import APIMiddleware from '../../../data/api/Api-Middleware';
import { formatDigitBasePrice, formatPriceUptoDecimals } from '../../../utils/format';

import { Ripple } from 'react-ripple-click';
import 'react-ripple-click/dist/index.css';
import StarSvg from './StarSvg';

const Symbol = () => {

    //Tabs  
    const [activeMode, setActiveMode] = useState('symbols');
    const [symbolCategory,setSymbolCategory] = useState('Cryptocurrency');
    const changeSymbolCategory=(e,o)=>{
        if(o === false){
            setSymbolCategory(e);
        }else{
            setSymbolCategory(null);
        }
    }
    //start click
    const [watchlistSymbols, setWatchlistSymbols] = useState({});

    const handleTdClick = (symbol) => {
        // Toggle the clicked status for the specific symbol
        setWatchlistSymbols((prevClickedSymbols) => ({
            ...prevClickedSymbols,
            [symbol]: !prevClickedSymbols[symbol],
        }));

        // Check the clicked status to determine whether to insert or delete watchlist data
        if (!watchlistSymbols[symbol]) {
            // If the symbol is not selected (clicked), insert it into the watchlist
            insertWatchlist(symbol);
        } else {
            // If the symbol is already selected (clicked), delete it from the watchlist
            deleteWatchlist(symbol);
        }
    };

    const starBoolean = (e) => {
        if (e.target.tagName == 'path') {
            if (e.target.parentNode.getAttribute('bool') == 'false') {
                e.target.parentNode.setAttribute('bool', true);
            } else {
                e.target.parentNode.setAttribute('bool', false);
            }
        } else {
            if (e.target.children[0].getAttribute('bool') == 'false') {
                e.target.children[0].setAttribute('bool', true);
            } else {
                e.target.children[0].setAttribute('bool', false);
            }
        }
    }

        //CONTEXT
    const { loadingSymbolContext, symbolData, symbolNames,  updateSymbolData, loadAllSymbolsData, updateSymbolDetailsData, symbolDetails, symbolWithCategories, symbolCategories, setSelectedCategoryId, allSymbolOptions,selectedSymbolSession, setSelectedSymbolSession } = useSymbolContext();
    const { user, platFromData, sendDataToServer, selectedAuthSymbol, setAuthSelectedSymbol,setAuthSelectedCategory,authSelectedCategory,selectedAuthSymbolId,setSelectedAuthSymbolId } = useAuthContext();
    const { selectedTimeFrame } = useChartContext();
    
    //everything must be initialize before further processing, so block everything base on this flag state
    const [isInitialized, setIsInitialized] = useState(false);
    const [isSymbolSelecting, setIsSymbolSelecting] = useState(false);


    const [isSymbolSelectingWatchlist, setIsSymbolSelectingWatchlist] = useState(false);


    const [searchInput, setSearchInput] = useState('');

    // //list data showing all symbols
    // const [symbolData, setSymbolData] = useState({});

    //create web-socket instance for the component
    const ws_symbol = ws_create();
    // const WS_MESSAGE_EVENT_LISTENER = "ws_message_symbols";
    const WS_MESSAGE_EVENT_LISTENER = "ws_message_platform";

    const [watchlistData, setWatchlistData] = useState([]);
    let isSymbolDetailsUpdated = false;

    // console.log("allSymbolOptions: ", allSymbolOptions)  
    useEffect(() => {
        if (selectedAuthSymbol != '') {
            localStorage.setItem('prevSymbol', localStorage.symbol);
            localStorage.setItem('symbol', selectedAuthSymbol);
            localStorage.setItem('category',authSelectedCategory);
        }
    }, [selectedAuthSymbol])

    useEffect(() => {
      if(selectedAuthSymbolId != ''){
            localStorage.setItem('symbol_id', selectedAuthSymbolId);
        }
    }, [selectedAuthSymbolId])
    useEffect(() => {
        if (user && user.userId != undefined && user.userId > 0) {
            //nothing will load in this component until SymbolContext fully loaded 
            if (!loadingSymbolContext) {
                //everything must be initialize before further processing, so block everything base on this flag state
                if (!isInitialized) {
                    const getSymbols = () => {
                        try {
                            const initialSymbolData = {};
                            // const initialSymbolCategoryData = {};

                            //setting initial values to all symbols
                            // symbolNames.forEach((symbol) => {

                            //     initialSymbolData[symbol] = { ask: null, bid: null, spread: null, ask_class: 'Symbol-price-same', bid_class: 'Symbol-price-same' };
                            // });
                            
                            symbolWithCategories.forEach((symbol) => {
                                
                                initialSymbolData[symbol.name] = {id:symbol.id, ask: symbol.initial_ask_price, bid: symbol.initial_bid_price, spread: null, ask_class: 'Symbol-price-same', bid_class: 'Symbol-price-same', symbol_category: symbol.symbol_category, is_session_active: symbol.is_session_active, };
                            });

                            // Filter out rows where the specified column is Disable
                            // const filteredData = result.filter(row => row.Status !== Disable);
                            // setData(filteredData);

                            //set symbol initial list data
                            loadAllSymbolsData(initialSymbolData);
                            
                            if (localStorage.symbol == null) {
                                updateSymbolDetailsData(symbolNames[0]);
                            } else {
                                updateSymbolDetailsData(localStorage.symbol);
                            }

                            //everything must be initialize before further processing, so block everything base on this flag state
                            setIsInitialized(true);

                            getWatchlistdata();

                        } catch (error) {
                            // Handle API request error
                            console.error(`API request error: ${API_ENDPOINT_SYMBOLS}`, error);
                        }
                    }

                    //load symbols in list
                    getSymbols();
                }

                //once everything initialized then further process base on this flag state
                if (isInitialized && platFromData.length > 0 && platFromData[0].length && platFromData[0] != undefined && platFromData[0] != 'undefined') {
                    let ask_class;
                    let bid_class;
                    //update list with updated live feed
                    const updateLiveFeedData = () => {

                        // Calculate spread and update only relevant parts
                        const updatedSymbolData = { ...symbolData };
                        // console.log("updated symbol data", updatedSymbolData.Crytocurrency);
                        platFromData[0].forEach((item) => {
                            const { s, a, b } = item;

                            // Calculate spread
                            // const spread = a - b;

                            // Determine the color based on the comparison
                            // let ask_class = 'Symbol-price-same';
                            // let bid_class = 'Symbol-price-same';                    

                            // format price and limit digits
                            // const ak = formatPrice(a);
                            // const bd = formatPrice(b);

                            // Update relevant data
                            if (updatedSymbolData[s]) {
                                const prevAsk = updatedSymbolData[s].ask;
                                const prevBid = updatedSymbolData[s].bid;

                                if (a < prevAsk) {
                                    ask_class = 'Symbol-price-down';
                                } else if (a > prevAsk) {
                                    ask_class = 'Symbol-price-up';
                                }

                                if (b < prevBid) {
                                    bid_class = 'Symbol-price-down';
                                } else if (b > prevBid) {
                                    bid_class = 'Symbol-price-up';
                                }

                                // updatedSymbolData[s].ask = a;
                                // updatedSymbolData[s].bid = b;
                                // // updatedSymbolData[s].spread = spread.toFixed(2);
                                // updatedSymbolData[s].ask_class = ask_class;
                                // updatedSymbolData[s].bid_class = bid_class;

                                updatedSymbolData[s].ask = a || updatedSymbolData[s].ask;
                                updatedSymbolData[s].bid = b || updatedSymbolData[s].bid;
                                // updatedSymbolData[s].spread = spread.toFixed(2);
                                updatedSymbolData[s].ask_class = ask_class || updatedSymbolData[s].ask_class;
                                updatedSymbolData[s].bid_class = bid_class || updatedSymbolData[s].bid_class;
                                // updatedSymbolData[s].symbol_category = updatedSymbolData[s].symbol_category;

                                //update selected symbol data context                    
                                if (selectedAuthSymbol) {
                                    if (selectedAuthSymbol === s) {
                                        updateSymbolData(s, b, a, updatedSymbolData[s].symbol_category);
                                    }
                                }
                                else {
                                    updateSymbolData(symbolNames[0], b, a, 1);
                                }
                            }

                        });

                        // loadAllSymbolsData(updatedSymbolData);
                    };

                    //Calling this function to show symbols data
                    updateLiveFeedData();

                    return async () => {
                        // clean up websocket connection when the component unmounts
                        //ws_symbol.ws_disconnect(WS_ENDPOINT_SYMBOLS_LIVE_FEEDS(user?.userId));
                        //ws_close_all();
                        // document.removeEventListener(WS_MESSAGE_EVENT_LISTENER, handleLiveFeedData);
                    };
                }
            }
        }

    }, [loadingSymbolContext, isInitialized, selectedAuthSymbol, user, selectedTimeFrame, platFromData[0]]);

    useEffect(() => {
        if (symbolCategories?.length) {
            changeSymbolCategory(symbolCategories[0].name, false);
        }
    }, [symbolCategories?.length]);

    const getWatchlistdata = async () => {
        try {
            // Use the API_ENDPOINT_USER_WATCHLIST in the fetch request
            const response = await APIMiddleware.get(API_ENDPOINT_USER_WATCHLIST(user.userId));

            if (response.data) {
                const symbols = response.data;
                setWatchlistSymbols(() => {
                    // Use reduce to iterate over the symbols array and create an object with true values
                    const updatedSymbols = symbols.reduce((acc, symbol) => {
                        acc[symbol.name] = true;
                        return acc;
                    }, {});

                    return updatedSymbols;
                });

            }
        } catch (error) {
            console.error('Error fetching watchlist data:', error);
        }
    };

    const insertWatchlist = async (symbol) => {
        try {
            // Call the API endpoint that triggers the stored procedure
            await APIMiddleware.post(API_ENDPOINT_INSERT_WATCHLIST(user.userId, symbol));
        } catch (error) {
            console.error('Error inserting watchlist item:', error);
        }
    };

    const deleteWatchlist = async (symbol) => {
        try {
            // Call the API endpoint that triggers the stored procedure for deletion
            await APIMiddleware.post(API_ENDPOINT_DELETE_WATCHLIST(user.userId, symbol));
            console.log('Watchlist item deleted successfully.');
        } catch (error) {
            console.error('Error deleting watchlist item:', error);
        }
    };

    // Filter symbols based on the search input
    const filteredSymbols = Object.keys(symbolData).filter((symbol) =>
        symbol.toLowerCase().includes(searchInput.toLowerCase())
    );

    //handle symbols list row clicks
    const handleRowClick = (symbolName,symbolData) => {
        setIsSymbolSelecting(true);
        setIsSymbolSelectingWatchlist(true);
            // Update symbol context data when a row is clicked      
            updateSymbolData(symbolName, symbolData[symbolName].bid, symbolData[symbolName].ask, symbolData[symbolName].symbol_category);
            setSelectedCategoryId(symbolData[symbolName].symbol_category);
            setSelectedSymbolSession(symbolData[symbolName].is_session_active);
            // updateSymbolDetailsData(symbolName);
    setAuthSelectedCategory(symbolData[symbolName].symbol_category);
            setAuthSelectedSymbol(symbolName);
           
            setSelectedAuthSymbolId(symbolData[symbolName]?.id);
            // if (window.innerWidth <= 960) {
            //     document.body.setAttribute('mobilecurrentview', 'chart');
            // }
        setTimeout(() => {
            setIsSymbolSelecting(false);
            setIsSymbolSelectingWatchlist(false);
        }, 4000);
    };

    //show spinner while loading data
    if (loadingSymbolContext) {
        return <Spinner />
    }
    // Determine classes based on activeMode
    const SymbolPanelClass = activeMode === 'symbols' ? 'visible' : 'hidden';
    const WatchListClass = activeMode === 'watchlists' ? 'visible' : 'hidden';

    const handleModeClick = (mode) => {
        setActiveMode(mode);
    };

    return (
        <div className='global-platfrom mobile-styled' style={{height: 'calc(100% - 70px)'}} >

            {/* Tab section */}

            <div className="symbol-mode-tabs global-platfrom ">
                <button
                    className={`mode-tab-button ${activeMode === 'symbols' ? 'mode-tab-active' : ''}`}
                    onClick={() => {
                        handleModeClick('symbols');
                        // mkRipple();
                    }}
                    style={{
                        position: 'relative',
                        overflow: 'hidden',
                        isolation: 'isolate',
                    }}
                >
                    <Ripple />
                    All Symbols
                </button>
                <button
                    className={`mode-tab-button ${activeMode === 'watchlists' ? 'mode-tab-active' : ''}`}
                    onClick={() => {
                        handleModeClick('watchlists');
                        // mkRipple();
                    }}
                    style={{
                        position: 'relative',
                        overflow: 'hidden',
                        isolation: 'isolate',
                      }}
                >
                    <Ripple />
                    WatchLists
                </button>
            </div>

            <div className={`Symbol ${SymbolPanelClass} symbol-panel-parent`}>
                <div className="Symbol global-platfrom symbol-panel-height" >
                    {/* Search input */}
                    <div className="search-container">
                        <FaSearch className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search symbol..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            className='className=input-symbol-field'
                        />
                        {searchInput && (
                            <FaTimes
                                className="clear-icon-symbol"
                                onClick={() => setSearchInput('')}
                            />
                        )}
                    </div>
                    <div className=''>
                        <table className="Symbol-table-head">
                            <thead className="Symbol-table-thead">
                                    <th className='star-th'></th>
                                    <th className='session-th'></th>
                                    <th className='symbol-th'>Symbol</th>
                                    <th className='bid-th'>Bid</th>
                                    <th className='ask-th'>Ask</th>
                            </thead>
                        </table>
                    </div>
                    
                    <div className="Symbol-list">
                        {symbolCategories.map((category) => (
                            searchInput.length === 0 ?
                           <wrapper key={category.id}>
                                <heading onClick={()=>{changeSymbolCategory(category.name,symbolCategory === category.name ? true : false)}} >
                                <div className='indicator' >
                                <svg viewBox="0 0 14 8" style={{
                                                    transform: symbolCategory == category.name ? 'rotate(90deg)' : 'none',
                                                              }} >
                                <path d="M1 1L7 7L13 1" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </div>  
                                {category.name}</heading>
                                
                                { symbolCategory === category.name ? (
                                <table className="Symbol-table" id="symbolTable">
                                    <tbody className="Symbol-tbody" id="symbolList">
                                        {/* {filteredSymbols.map((symbol) => { */}
                                        {Object.entries(symbolData).map(([symbol, data]) => {
                                            const isSelected = selectedAuthSymbol === symbol;
                                            if (data.symbol_category === category.id && filteredSymbols.includes(symbol)) {
                                                return (
                                                    <tr className={`Symbol-tr ${isSelected ? 'selected-row' : ''} ${isSymbolSelecting ? 'row-disabled' : ''}`} key={symbol}>
                                                        <StarSvg handleTdClick={handleTdClick} symbol={symbol} watchlistSymbols={watchlistSymbols[symbol] || false} >Add to Watchlist</StarSvg>
                                                        <td className={`specific-td session-td`}>
                                                            {data.is_session_active === 0 ? (
                                                                <div className='pause-mark'>||</div>
                                                            ) : (
                                                                <div className='check-mark'>
                                                                    <svg
                                                                        id='checkmarkId'
                                                                        width={16}
                                                                        height={16}
                                                                        viewBox="0 0 24 24"
                                                                        fill={"#21c46d"}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M9 19.4l-7-7L3.4 10 9 15.6 20.6 4 22 5.4z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="Symbol-td symbol-td" onClick={() => {
                                                            handleRowClick(symbol,symbolData); 
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                            }}
                                                            >
                                                                <Ripple />
                                                                {symbol}
                                                            </td>
                                                        <td className={`Symbol-td ${data.bid_class} bid-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.bid ? formatPriceUptoDecimals(data.bid, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                        <td className={`Symbol-td ${data.ask_class} ask-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.ask ? formatPriceUptoDecimals(data.ask, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                            return null;
                                        })}
                                    </tbody>
                                </table>
                                ) : null
                                }
                            </wrapper> : 
                           <wrapper>
                                <heading onClick={()=>{changeSymbolCategory(category.name,symbolCategory === category.name ? true : false)}} >
                                <div className='indicator' >
                                <svg viewBox="0 0 14 8" style={{
                                                    transform: symbolCategory == category.name ? 'rotate(90deg)' : 'none',
                                                              }}>
                                <path d="M1 1L7 7L13 1" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </div>  
                                {category.name}</heading>
                                <table className="Symbol-table" id="symbolTable">
                                    <tbody className="Symbol-tbody" id="symbolList">
                                        {/* {filteredSymbols.map((symbol) => { */}
                                        {Object.entries(symbolData).map(([symbol, data]) => {
                                            const isSelected = selectedAuthSymbol === symbol;
                                            if (data.symbol_category === category.id && filteredSymbols.includes(symbol)) {
                                                return (
                                                    <tr className={`Symbol-tr ${isSelected ? 'selected-row' : ''} ${isSymbolSelecting ? 'row-disabled' : ''}`} key={symbol}>

                                                        <StarSvg handleTdClick={handleTdClick} symbol={symbol} watchlistSymbols={watchlistSymbols[symbol] || false} >Add to Watchlist</StarSvg> 
                                                        <td className={`specific-td session-td`}>
                                                            {data.is_session_active === 0 ? (
                                                                <div className='pause-mark'>||</div>
                                                            ) : (
                                                                <div className='check-mark'>
                                                                    <svg
                                                                        id='checkmarkId'
                                                                        width={16}
                                                                        height={16}
                                                                        viewBox="0 0 24 24"
                                                                        fill={"#21c46d"}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M9 19.4l-7-7L3.4 10 9 15.6 20.6 4 22 5.4z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="Symbol-td symbol-td" onClick={() => {
                                                            handleRowClick(symbol,symbolData); 
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                            }}
                                                            >
                                                                <Ripple />
                                                                {symbol}
                                                            </td>
                                                        <td className={`Symbol-td ${data.bid_class} bid-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.bid ? formatPriceUptoDecimals(data.bid, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                        <td className={`Symbol-td ${data.ask_class} ask-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.ask ? formatPriceUptoDecimals(data.ask, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                            return null;
                                        })}
                                    </tbody>
                                </table>
                            </wrapper> 
                        ))}
                    </div>
                </div>
            </div>
            <div className={`order-book-wrapper ${WatchListClass}`}>
                <div className="Symbol global-platfrom" style={{ height: '100%' }} >
                    {/* Search input */}
                    <div className="search-container">
                        <FaSearch className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search symbol..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            className='input-symbol-field'
                        />
                        {searchInput && (
                            <FaTimes
                                className="clear-icon-symbol"
                                onClick={() => setSearchInput('')}
                            />
                        )}
                    </div>

                    {/* WATCH LIST TAB  */}

                    <div className=''>
                        <table className="Symbol-table-head">
                            <thead className="Symbol-table-thead">
                                    <th className='star-th'></th>
                                    <th className='session-th'></th>
                                    <th className='symbol-th'>Symbol</th>
                                    <th className='bid-th'>Bid</th>
                                    <th className='ask-th'>Ask</th>
                            </thead>
                        </table>
                    </div>
                    
                    <div className="Symbol-list">
                        {symbolCategories.map((category) => (
                            searchInput.length === 0 ?
                           <wrapper key={category.id}>
                                <heading onClick={()=>{changeSymbolCategory(category.name,symbolCategory === category.name ? true : false)}} >
                                <div className='indicator' >
                                <svg viewBox="0 0 14 8" style={{
                                            transform: symbolCategory == category.name ? 'rotate(90deg)' : 'none',
                                                            }}>
                                <path d="M1 1L7 7L13 1" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </div>  
                                {category.name}</heading>
                                
                                { symbolCategory === category.name ? (
                                <table className="Symbol-table" id="symbolTable">
                                    <tbody className="Symbol-tbody" id="symbolList">
                                        {/* {filteredSymbols.map((symbol) => { */}
                                        {Object.entries(symbolData)
                                            .filter(([symbol]) => watchlistSymbols[symbol]) // Only include watchlist symbols
                                            .map(([symbol, data]) => {
                                                const isSelected = selectedAuthSymbol === symbol;
                                                if (data.symbol_category === category.id && filteredSymbols.includes(symbol)) {
                                                    return (
                                                    <tr className={`Symbol-tr ${isSelected ? 'selected-row' : ''} ${isSymbolSelecting ? 'row-disabled' : ''}`} key={symbol}>

                                                        <StarSvg handleTdClick={handleTdClick} symbol={symbol} watchlistSymbols={watchlistSymbols[symbol] || false} >Remove From Watchlist</StarSvg> 
                                                        <td className={`specific-td session-td`}>
                                                            {data.is_session_active === 0 ? (
                                                                <div className='pause-mark'>||</div>
                                                            ) : (
                                                                <div className='check-mark'>
                                                                    <svg
                                                                        id='checkmarkId'
                                                                        width={16}
                                                                        height={16}
                                                                        viewBox="0 0 24 24"
                                                                        fill={"#21c46d"}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M9 19.4l-7-7L3.4 10 9 15.6 20.6 4 22 5.4z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="Symbol-td symbol-td" onClick={() => {
                                                            handleRowClick(symbol,symbolData); 
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                            }}
                                                            >
                                                                <Ripple />
                                                                {symbol}
                                                            </td>
                                                        <td className={`Symbol-td ${data.bid_class} bid-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.bid ? formatPriceUptoDecimals(data.bid, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                        <td className={`Symbol-td ${data.ask_class} ask-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.ask ? formatPriceUptoDecimals(data.ask, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                            return null;
                                        })}
                                    </tbody>
                                </table>
                                ) : null
                                }
                            </wrapper> : 
                           <wrapper>
                                <heading onClick={()=>{changeSymbolCategory(category.name,symbolCategory === category.name ? true : false)}} >
                                <div className='indicator' >
                                <svg viewBox="0 0 14 8" style={{
                                    transform: symbolCategory == category.name ? 'rotate(90deg)' : 'none',
                                                            }}>
                                <path d="M1 1L7 7L13 1" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </div>  
                                {category.name}</heading>
                                <table className="Symbol-table" id="symbolTable">
                                    <tbody className="Symbol-tbody" id="symbolList">
                                        {/* {filteredSymbols.map((symbol) => { */}
                                        {Object.entries(symbolData)
                                            .filter(([symbol]) => watchlistSymbols[symbol]) // Only include watchlist symbols
                                            .map(([symbol, data]) => {
                                                const isSelected = selectedAuthSymbol === symbol;
                                                if (data.symbol_category === category.id && filteredSymbols.includes(symbol)) {
                                                    return (
                                                    <tr className={`Symbol-tr ${isSelected ? 'selected-row' : ''} ${isSymbolSelecting ? 'row-disabled' : ''}`} key={symbol}>

                                                    <StarSvg handleTdClick={handleTdClick} symbol={symbol} watchlistSymbols={watchlistSymbols[symbol] || false} >Remove From Watchlist</StarSvg> 
                                                        <td className={`specific-td session-td`}>
                                                            {data.is_session_active === 0 ? (
                                                                <div className='pause-mark'>||</div>
                                                            ) : (
                                                                <div className='check-mark'>
                                                                    <svg
                                                                        id='checkmarkId'
                                                                        width={16}
                                                                        height={16}
                                                                        viewBox="0 0 24 24"
                                                                        fill={"#21c46d"}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M9 19.4l-7-7L3.4 10 9 15.6 20.6 4 22 5.4z" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="Symbol-td symbol-td" onClick={() => {
                                                            handleRowClick(symbol,symbolData); 
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                            }}
                                                            >
                                                                <Ripple />
                                                                {symbol}
                                                            </td>
                                                        <td className={`Symbol-td ${data.bid_class} bid-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.bid ? formatPriceUptoDecimals(data.bid, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                        <td className={`Symbol-td ${data.ask_class} ask-td`} onClick={() => {
                                                            handleRowClick(symbol,symbolData);
                                                            // mkRipple(e);
                                                            }}
                                                            style={{
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                isolation: 'isolate',
                                                              }}
                                                            >
                                                                <Ripple />
                                                            {data.ask ? formatPriceUptoDecimals(data.ask, symbolDetails[symbol]?.digit).split('.').map((part, index) => (
                                                                index === 0 ? <span key={index}>{part}</span> : <span key={index} className='afterDecimal'>.{part}</span>
                                                            )) : '-'}
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                            return null;
                                        })}
                                    </tbody>
                                </table>
                            </wrapper> 
                        ))}
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Symbol;